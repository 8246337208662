// Row
//
// Rows contain your columns.

@if $enable-grid-classes {
    .row {
        @include make-row();

        > * {
            @include make-col-ready();
        }
    }
}

// .row {
//     @each $breakpoint, $gutter in $grid-gutter-widths {
//         @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
//             --#{$prefix}gutter-x: #{$gutter};
//             --#{$prefix}gutter-y: 0;

//             margin-right: calc(-0.5 * var(--#{$prefix}gutter-x)); // stylelint-disable-line function-disallowed-list
//             margin-left: calc(-0.5 * var(--#{$prefix}gutter-x)); // stylelint-disable-line function-disallowed-list
//             margin-top: calc(-1 * var(--#{$prefix}gutter-y)); // stylelint-disable-line function-disallowed-list
//         }

//         > * {
//             @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
//                 padding-right: calc(var(--#{$prefix}gutter-x) * 0.5); // stylelint-disable-line function-disallowed-list
//                 padding-left: calc(var(--#{$prefix}gutter-x) * 0.5); // stylelint-disable-line function-disallowed-list
//                 margin-top: var(--#{$prefix}gutter-y);
//             }
//         }
//     }
// }

@if $enable-cssgrid {
    .grid {
        display: grid;
        grid-template-rows: repeat(var(--#{$prefix}rows, 1), 1fr);
        grid-template-columns: repeat(var(--#{$prefix}columns, #{$grid-columns}), 1fr);
        gap: var(--#{$prefix}gap, #{$grid-gutter-width});

        @include make-cssgrid();
    }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
    @include make-grid-columns();
}
