@import "../../assets/scss/component-base.scss";

.static-page {
    h2 {
        margin-top: 3rem;
        margin-bottom: 1rem;

        &:first-child {
            margin-top: 0;
        }
    }

    ul li {
        margin-bottom: 0.5rem;
    }
}
