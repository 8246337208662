@import "../../../assets/scss/component-base.scss";

// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

%badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: $badge-padding-y $badge-padding-x;
    @include font-size($badge-font-size);
    font-weight: $badge-font-weight;
    line-height: 1;
    //color: $badge-color;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    //@include border-radius($badge-border-radius);
    border-radius: $badge-border-radius;
    @include gradient-bg();
}

.badge {
    @extend %badge;

    // Empty badges collapse automatically
    &:empty {
        display: none;
    }
}

// Quick fix for badges in buttons
.btn .badge {
    position: relative;
    //top: -1px;
}

// Badge Dot
.badge-dot {
    display: inline-flex;
    height: 0.5em;
    width: 0.5em;
    border-radius: 50%;
}

@each $color, $value in $theme-colors {
    .badge-dot--#{$color} {
        @include gradient-bg($value);
    }
}

.badge.bg-warning {
    color: shade-color($warning, 80%);
    background-color: tint-color($warning, 30%) !important;
}

//
// Alternate Badge
//

// Solid (Default)
@each $color, $value in $theme-colors {
    .badge--#{$color} {
        @include badge-variant($value);
    }
}

// Light
@each $color, $value in $theme-colors {
    .badge--light-#{$color} {
        @include badge-light-variant($value);
    }
}

//
// Correct Secondary default colors
//

.badge--outline-secondary {
    color: $body-color;
}

.badge--light-secondary {
    color: $body-color;
    background-color: $gray-200;
}

//
// Correct Success default colors
//

.badge--success {
    color: $white;
}

.badge--light-success {
    color: shade-color($success-600, 10%);
}

//
// Correct Warning default colors
//

.badge--warning {
    color: shade-color($warning-900, 30%);
    background-color: $warning-300;
}

.badge--light-warning {
    color: $warning-900;
    background-color: $warning-100;
}

//
// Badge Group
//

.badge-group {
    position: relative;
    display: inline-flex;
    vertical-align: middle; // match .btn alignment given font-size hack above

    // Reset rounded corners
    > .badge:not(:last-child) {
        @include border-end-radius(0);
    }

    // The left radius should be 0 if the badge is:
    // - the "third or more" child
    // - the second child
    > .badge:nth-child(n + 3),
    > .badge:not(:first-child) {
        @include border-start-radius(0);
    }
}

// Badge with SVG
.badge svg {
    fill: currentColor;
}
