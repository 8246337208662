.overflow-x-auto {
    @include scrollable-x;
}
.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-scroll {
    overflow-x: scroll;
}
.overflow-y-scroll {
    overflow-y: scroll;
}
