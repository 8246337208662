@import "../../assets/scss/component-base.scss";

// Chip styles
//
// Chips allow users to enter information, make selections, filter content, or trigger actions.
//

%chip {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include font-size($chip-font-size);
    font-weight: $chip-font-weight;
    line-height: 1;
    color: $chip-color;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: $chip-border-radius;
}

.chip {
    @extend %chip;

    // Empty chips collapse automatically
    &:empty {
        display: none;
    }
}

.chip-label {
    //padding: $chip-padding-y $chip-padding-x;
    padding-left: 0.75rem;
    padding-right: 0.25rem;
    @include text-truncate();
    max-width: 160px;
}

.chip-close-button {
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0.25rem;
    margin: 0.25rem;
    border: 0;
    border-radius: $border-radius-pill;
    transition: $chip-transition;

    svg {
        width: 1rem;
        height: 1rem;
    }
}

//
// Alternate Chips
//

.chip--light-secondary {
    color: $body-color;
    background-color: $gray-200;

    .chip-close-button {
        color: $body-color;
        background-color: $gray-200;

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $gray-300;
            }
        }
    }
}

.chip--light-primary {
    color: $primary;
    background-color: $primary-100;

    .chip-close-button {
        color: $primary-700;
        background-color: inherit;
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $primary-200;
            }
        }
    }
}
