@import "../../../assets/scss/component-base.scss";

.card-product {
    position: relative;
    display: flex;
    flex-direction: column;
}

.card-product__media {
    position: relative;
    background-color: $gray-100;
}

.card-product__tags {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 1;
}

.card-product__code {
    position: absolute;
    bottom: 0.5rem;
    right: 0.75rem;
    @include media-breakpoint-up(sm) {
        bottom: 1rem;
        right: 1rem;
    }
    z-index: 1;
    font-size: $font-size-sm;
}

.card-product__body {
    // Enable `flex-grow: 1` for decks and groups so that card blocks take up
    // as much space as possible, ensuring footers are aligned to the bottom.
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 0.75rem 0.5rem;

    @include media-breakpoint-up(xl) {
        padding: $card-spacer-y $card-spacer-x;
    }
    @include media-breakpoint-up(sm) {
        padding: 0.75rem;
    }
}
.card-product__title {
    font-size: $font-size-base;
    line-height: 1.4;

    @include media-breakpoint-up(xl) {
        font-size: $font-size-lg;
    }

    @include media-breakpoint-down(sm) {
        font-size: 0.9375rem;
        line-height: 1.3;

        // Line clamp
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    a {
        color: $headings-color;
        &:hover,
        &:focus {
            color: $headings-color;
            opacity: 0.85;
        }
    }
}

.card-product .tag-series {
    font-size: 90%;
}

.price {
    display: flex;
    flex-wrap: wrap;

    del {
        color: $text-muted;
        margin-right: 0.5rem;
    }

    ins {
        color: $danger;
        font-weight: $font-weight-semibold;
        text-decoration: none;
    }
}

.card-product__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    .btn-add-to-cart {
        white-space: nowrap;
    }
}

@include media-breakpoint-up(xl) {
    .card-product__actions {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0.5rem;
        margin-top: 0.5rem;
    }
}

// **** Alvaro: trampillas para fotos viejas **** //
.old-main-image {
    padding-bottom: 100%;
    // background: #fff;
}
.old-main-image img {
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    mix-blend-mode: multiply;
}

.card-product__media > .skeleton {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
}

// article.card-product.layout--horizontal {
//     display: flex;
//     flex-direction: row;
//     width: 100%;
// }
// article.card-product.layout--horizontal .card-product__media {
//     max-width: 90px;
//     flex-grow: 0;
//     flex-shrink: 0;
// }
// article.card-product.layout--horizontal .card-product__body {
//     flex-grow: 1;
//     flex-shrink: 1;
// }
// article.card-product.layout--horizontal .card-product__media .card-product__code {
//     display: none;
// }
// article.card-product.layout--horizontal .card-product__body .card-product__title {
//     text-align: left;
// }
