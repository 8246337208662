@import "../../assets/scss/component-base.scss";

// Contents
//
// Navbar
// Navbar nav
// Navbar position
// Navbar themes

// Navbar
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap; // allow us to do the line break for collapsing content
    align-items: center;
    @include gradient-bg();

    // Because flex properties aren't inherited, we need to redeclare these first
    // few properties so that content nested within behave properly.
    // The `flex-wrap` property is inherited to simplify the expanded navbars
    %container-flex-properties {
        display: flex;
        flex-wrap: inherit;
        align-items: center;
        height: 100%;
    }

    > .container {
        @extend %container-flex-properties;
    }
}

// Navbar nav
//
//

.navbar-nav {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    height: 100%;

    .navbar-nav-item {
        height: 100%;
        // margin-right: $navbar-nav-item-margin-x;
        // margin-left: $navbar-nav-item-margin-x;
    }

    .navbar-nav-link {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        padding-right: $navbar-nav-link-padding-x;
        padding-left: $navbar-nav-link-padding-x;
        font-family: $font-family-brand;
        letter-spacing: $headings-letter-spacing;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            opacity: 0;
            border-bottom: 3px solid $primary-300;
            transform: scaleX(0);
            transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        }

        &:hover {
            &:after {
                opacity: 1;
                transform: scaleX(1);
            }
        }
    }

    .navbar-dropdown-menu {
        position: absolute;
    }

    .navbar-nav-scroll {
        overflow: visible;
    }
}

// Navbar themes
//
// Styles for switching between navbars with light or dark background.

// Dark links against a light background
.navbar--light {
    .navbar-nav {
        .navbar-nav-link {
            color: $navbar-light-color;

            &:hover,
            &:focus {
                color: $navbar-light-hover-color;
            }

            &.disabled {
                color: $navbar-light-disabled-color;
            }
        }

        .show > .navbar-nav-link,
        .navbar-nav-link.active {
            color: $navbar-light-active-color;
            font-weight: $font-weight-medium;
        }
    }
}

// White links against a dark background
.navbar--dark {
    .navbar-nav {
        .navbar-nav-link {
            color: $navbar-dark-color;

            &:hover,
            &:focus {
                color: $navbar-dark-hover-color;
            }

            &.disabled {
                color: $navbar-dark-disabled-color;
            }
        }

        .show > .navbar-nav-link,
        .navbar-nav-link.active {
            color: $navbar-dark-active-color;
            font-weight: $font-weight-medium;
        }
    }
}

// Navbar Dropdown

.navbar-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    flex-direction: row;
    width: 100vw;
    min-height: 40vh;
    background-color: $white;
    z-index: $zindex-dropdown;
    visibility: hidden;

    // * Option 1 *

    //opacity: 0;
    //transition: opacity, visibility 0.25s ease 0.15s;

    // * Option 2 *

    transform: scaleY(0);
    transform-origin: center top;
    transition: visibility, transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;

    > .container {
        opacity: 0;
        transition: opacity 0.25s ease 0.25s;
        max-width: 1600px;
    }

    &.show {
        visibility: visible;

        // * Option 1 *

        //opacity: 1;

        // * Option 2 *

        transform: none;

        > .container {
            opacity: 1;
            transition-delay: 0.45s;
        }
    }
}

// .navbar-nav-item:hover .navbar-dropdown {
//     visibility: visible;

//     // * Option 1 *

//     //opacity: 1;

//     // * Option 2 *

//     transform: none;

//     > .container {
//         opacity: 1;
//         transition-delay: 0.45s;
//     }
// }

// Navbar Submenu

.navbar-submenu-title a {
    color: $headings-color;
}

.navbar-submenu {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    .navbar-submenu-link {
        display: inline-flex;
        align-items: center;
        padding: 0.25rem 0;
        @include font-size($nav-link-font-size);
        font-weight: $nav-link-font-weight;
        @include transition($nav-link-transition);

        color: $navbar-light-color;

        &:hover,
        &:focus {
            color: $navbar-light-hover-color;
        }

        // Disabled state lightens text
        &.disabled {
            color: $navbar-light-disabled-color;
            pointer-events: none;
            cursor: default;
        }
    }
}

.navbar-dropdown {
    .card-lathe__title {
        @include font-size(1rem);
    }
}

.header-backdrop {
    display: block;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    backface-visibility: hidden;
    opacity: 0;
    background: rgba($gray-900, 0.6);
    transition: visible, opacity 0.25s linear 0.15s;
}

.show.header-backdrop {
    visibility: visible;
    opacity: 1;
}
