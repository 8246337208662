@import "../../../../../assets/scss/component-base.scss";

.shipping-packages {
    .accordion:last-child {
        border-bottom: 0;
    }
}

.shipping-package {
    .cart-line {
        border-bottom: 0;
    }
}
