// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff !default;
$black: #000 !default;

$gray-50: #f9fafb !default;
$gray-100: #f3f4f6 !default;
$gray-200: #e5e7eb !default;
$gray-300: #d1d5db !default;
$gray-400: #9ca3af !default;
$gray-500: #6b7280 !default;
$gray-600: #4b5563 !default;
$gray-700: #374151 !default;
$gray-800: #1f2937 !default;
$gray-900: #111827 !default;

$grays: (
    "50": $gray-50,
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
) !default;

$pink: #d63384;
$pink-50: tint-color($pink, 90%) !default;
$pink-100: tint-color($pink, 80%) !default;
$pink-200: tint-color($pink, 60%) !default;
$pink-300: tint-color($pink, 40%) !default;
$pink-400: tint-color($pink, 20%) !default;
$pink-500: #d63384 !default;
$pink-600: shade-color($pink, 20%) !default;
$pink-700: shade-color($pink, 40%) !default;
$pink-800: shade-color($pink, 60%) !default;
$pink-900: shade-color($pink, 80%) !default;

$danger-50: #fef2f2 !default;
$danger-100: #fee2e2 !default;
$danger-200: #fecaca !default;
$danger-300: #fca5a5 !default;
$danger-400: #f87171 !default;
$danger-500: #ef4444 !default;
$danger-600: #dc2626 !default;
$danger-700: #b91c1c !default;
$danger-800: #991b1b !default;
$danger-900: #7f1d1d !default;

$warning-50: #fffbeb !default;
$warning-100: #fef3c7 !default;
$warning-200: #fde68a !default;
$warning-300: #fcd34d !default;
$warning-400: #fbbf24 !default;
$warning-500: #f59e0b !default;
$warning-600: #d97706 !default;
$warning-700: #b45309 !default;
$warning-800: #92400e !default;
$warning-900: #78350f !default;

$success-50: #ecfdf5 !default;
$success-100: #dcfce7 !default;
$success-200: #bbf7d0 !default;
$success-300: #86efac !default;
$success-400: #4ade80 !default;
$success-500: #22c55e !default;
$success-600: #16a34a !default;
$success-700: #15803d !default;
$success-800: #166534 !default;
$success-900: #14532d !default;

$primary-50: #f1f5fc !default;
$primary-100: #e1eafa !default;
$primary-200: #c2d8ff !default;
$primary-300: #7ea8f1 !default;
$primary-400: #4183f1 !default;
$primary-500: #0c5ee9 !default;
$primary-600: #004bcc !default;
$primary-700: #003da5 !default;
$primary-800: #002970 !default;
$primary-900: #001a47 !default;

$primary-colors: (
    "50": $primary-50,
    "100": $primary-100,
    "200": $primary-200,
    "300": $primary-300,
    "400": $primary-400,
    "500": $primary-500,
    "600": $primary-600,
    "700": $primary-700,
    "800": $primary-800,
    "900": $primary-900,
) !default;

$primary: $primary-700 !default;
$secondary: $gray-900 !default;
$success: $success-600 !default;
$info: $primary-500 !default;
$warning: $warning-400 !default;
$danger: $danger-600 !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
) !default;

$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value") !default;

$button-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "danger": $danger,
    "white": $white,
) !default;

//$button-colors-rgb: map-loop($button-colors, to-rgb, "$value") !default;

$badge-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "white": $white,
) !default;

//$badge-colors-rgb: map-loop($badge-colors, to-rgb, "$value") !default;

$alert-colors: (
    "primary": $primary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
) !default;

// $alert-colors-rgb: map-loop($alert-colors, to-rgb, "$value") !default;

// Gradients

$gradient-primary-90-deg: linear-gradient(90deg, rgba($primary-800, 0.96), rgba($primary-900, 0.96)) !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast

$min-contrast-ratio: 4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.

$color-contrast-dark: $gray-900 !default;
$color-contrast-light: $white !default;

// Characters which are escaped by the escape-svg function

$escaped-characters: (("<", "%3c"), (">", "%3e"), ("#", "%23"), ("(", "%28"), (")", "%29")) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: false !default;
$enable-shadows: false !default;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-smooth-scroll: true !default;
$enable-grid-classes: true !default;
$enable-cssgrid: false !default;
$enable-button-pointers: true !default;
$enable-rfs: true !default;
$enable-validation-icons: true !default;
$enable-negative-margins: false !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities: true !default;

// Prefix for :root CSS variables

$prefix: cmz- !default;
$prefix: $prefix !default;

// Spacing
//
// Control the default styling of most elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.25,
    5: $spacer * 1.5,
    6: $spacer * 2,
    7: $spacer * 2.5,
    8: $spacer * 3,
    9: $spacer * 3.5,
    10: $spacer * 4,
    11: $spacer * 4.5,
    12: $spacer * 5,
    13: $spacer * 5.5,
    14: $spacer * 6,
) !default;

// Negative margins
//
// This map is for generate negative margin utility classes. Negative margins are disabled by default.

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;

// Position
//
// Define the edge positioning anchors of the position utilities.

$position-values: (
    0: 0,
    50: 50%,
    100: 100%,
) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: $gray-700 !default;
$body-text-align: null !default;

// Utilities maps
//
// Extends the default `$theme-colors` maps to help create our utilities.

// Come v6, we'll de-dupe these variables. Until then, for backward compatibility, we keep them to reassign.

$utilities-colors: $theme-colors-rgb !default;

$utilities-text: map-merge(
    $utilities-colors,
    (
        "black": to-rgb($black),
        "white": to-rgb($white),
        "body": to-rgb($body-color),
    )
) !default;
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, "$key", "text") !default;

$utilities-bg: map-merge(
    $utilities-colors,
    (
        "black": to-rgb($black),
        "white": to-rgb($white),
        "body": to-rgb($body-bg),
    )
) !default;
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, "$key", "bg") !default;

// Links
//
// Style anchor elements.

$link-color: $primary !default;
$link-decoration: none !default;
$link-shade-percentage: 20% !default;
$link-hover-color: shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration: null !default;

$stretched-link-pseudo-element: after !default;
$stretched-link-z-index: 1 !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1024px,
    xl: 1200px,
    xxl: 1400px,
    xxxl: 1800px,
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// $container-max-widths: (
//     sm: 540px,
//     md: 720px,
//     lg: 992px,
//     xl: 1140px,
//     xxl: 1320px,
// ) !default;

// @include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 1.25rem !default;
$grid-row-columns: 6 !default;

$gutters: $spacers !default;

$grid-gutter-widths: (
    xs: 0.75rem,
    sm: 1rem,
    md: 1rem,
    lg: 1.5rem,
    xl: 1.5rem,
    xxl: 1.5rem,
    xxxl: 2rem,
) !default;

// Container padding

$container-padding-x: 1.25rem !default;

$container-paddings-x: (
    xs: 1rem,
    sm: 1.25rem,
    md: 1.5rem,
    lg: 2.5rem,
    xl: 3rem,
    xxl: 5rem,
    xxxl: 5rem,
) !default;

$container-inner-max-width: 1800px;
$container-max-width: $container-inner-max-width + (80px * 2);

// Components
//
// Define common padding and border radius sizes and more.

$border-width: 1px !default;
$border-widths: (
    1: 1px,
    2: 2px,
    3: 3px,
    4: 4px,
    5: 5px,
) !default;

$border-style: solid !default;
$border-color: $gray-200 !default;
$border-color-translucent: rgba($gray-900, 0.175) !default;

$border-radius: 0.375rem !default;
$border-radius-xs: 0.125rem !default;
$border-radius-sm: 0.25rem !default;
$border-radius-lg: 0.5rem !default;
$border-radius-xl: 1rem !default;
$border-radius-2xl: 2rem !default;
$border-radius-pill: 50rem !default;

$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.15) !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.075) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.175) !default;
$box-shadow-inset: inset 0 1px 2px rgba($gray-900, 0.075) !default;

$component-active-color: $white !default;
$component-active-bg: $primary !default;

$component-active-color-light: $primary !default;
$component-active-bg-light: $primary-50 !default;

$caret-width: 0.3em !default;
$caret-vertical-align: $caret-width * 0.85 !default;
$caret-spacing: $caret-width * 0.85 !default;

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;
$transition-collapse-width: width 0.35s ease !default;

$aspect-ratios: (
    "1x1": 100%,
    "4x3": calc(3 / 4 * 100%),
    "16x9": calc(9 / 16 * 100%),
    "21x9": calc(9 / 21 * 100%),
) !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

$font-family-base: "Inter", $font-family-sans-serif !default;
$font-family-brand: "Panton", $font-family-sans-serif !default;
$font-family-lathes: "1st Enterprises Expanded", $font-family-sans-serif !default;
$font-family-code: var(--#{$prefix}font-monospace) !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: null !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-xs: $font-size-base * 0.75 !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.125 !default;
$font-size-xl: $font-size-base * 1.25 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;

$h1-font-size: $font-size-base * 2.25 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base * 1.125 !default;

$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size,
    base: $font-size-base,
    sm: $font-size-sm,
    xs: $font-size-xs,
) !default;

// Headings

$headings-margin-bottom: $spacer * 0.5 !default;
$headings-font-family: $font-family-brand !default;
$headings-font-style: null !default;
$headings-font-weight: 600 !default;
$headings-line-height: 1.2 !default;
$headings-letter-spacing: 0.02em !default;
$headings-color: $gray-900 !default;

// Display headings

$display-font-sizes: (
    1: 5rem,
    2: 4.5rem,
    3: 4rem,
    4: 3.5rem,
    5: 3rem,
    6: 2.5rem,
) !default;

$display-font-family: null !default;
$display-font-style: null !default;
$display-font-weight: $headings-font-weight !default;
$display-line-height: $headings-line-height !default;

// Lead paragraphs

$lead-font-size: $font-size-base * 1.25 !default;
$lead-font-weight: 300 !default;

// Text elements

$small-font-size: 0.875em !default;

$sub-sup-font-size: 0.75em !default;

$text-muted: $gray-500 !default;

$initialism-font-size: $small-font-size !default; // Add .initialism to an abbreviation for a slightly smaller font-size.

$blockquote-margin-y: $spacer !default;
$blockquote-font-size: $font-size-base * 1.25 !default;
$blockquote-footer-color: $gray-600 !default;
$blockquote-footer-font-size: $small-font-size !default;

$hr-margin-y: $spacer !default;
$hr-color: inherit !default;
$hr-border-color: null !default; // Allows for inherited colors
$hr-border-width: $border-width !default;
$hr-opacity: 0.25 !default;

$legend-margin-bottom: 0.5rem !default;
$legend-font-size: 1.5rem !default;
$legend-font-weight: null !default;

$dt-font-weight: $font-weight-bold !default;

$list-inline-padding: 1rem !default;

$mark-padding: 0.1875em !default;
$mark-bg: $warning-100 !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y: 0.5rem !default;
$table-cell-padding-x: 0.5rem !default;
$table-cell-padding-y-sm: 0.25rem !default;
$table-cell-padding-x-sm: 0.25rem !default;

$table-cell-vertical-align: top !default;

$table-color: var(--#{$prefix}body-color) !default;
$table-bg: transparent !default;
$table-accent-bg: transparent !default;

$table-th-font-weight: null !default;

$table-striped-color: $table-color !default;
$table-striped-bg-factor: 0.05 !default;
$table-striped-bg: rgba($black, $table-striped-bg-factor) !default;

$table-active-color: $table-color !default;
$table-active-bg-factor: 0.1 !default;
$table-active-bg: rgba($black, $table-active-bg-factor) !default;

$table-hover-color: $table-color !default;
$table-hover-bg-factor: 0.075 !default;
$table-hover-bg: rgba($black, $table-hover-bg-factor) !default;

$table-border-factor: 0.1 !default;
$table-border-width: $border-width !default;
$table-border-color: var(--#{$prefix}border-color) !default;

$table-striped-order: odd !default;
$table-striped-columns-order: even !default;

$table-group-separator-color: currentcolor !default;

$table-caption-color: $text-muted !default;

$table-bg-scale: -80% !default;
// scss-docs-end table-variables

// scss-docs-start table-loop
$table-variants: (
    "primary": shift-color($primary, $table-bg-scale),
    "secondary": shift-color($secondary, $table-bg-scale),
    "success": shift-color($success, $table-bg-scale),
    "info": shift-color($info, $table-bg-scale),
    "warning": shift-color($warning, $table-bg-scale),
    "danger": shift-color($danger, $table-bg-scale),
    "light": $light,
    "dark": $dark,
) !default;
// scss-docs-end table-loop

//
// *** COMPONENTS ***
//

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-font-family: null !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-line-height: $line-height-base !default;

//$input-btn-focus-width: 0.25rem !default;
$input-btn-focus-width: 0 !default;
$input-btn-focus-color-opacity: 0.25 !default;
$input-btn-focus-color: rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-blur: 0 !default;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm: 0.375rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
$input-btn-font-size-sm: $font-size-sm !default;
$input-btn-line-height-sm: $line-height-base !default;

$input-btn-padding-y-lg: 0.75rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-font-size-lg: $font-size-base !default;
$input-btn-line-height-lg: $line-height-base !default;

$input-btn-border-width: $border-width !default;
// scss-docs-end input-btn-variables

// Buttons
//
// For each buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: 1.25rem !default;

$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $input-btn-font-size !default;
$btn-line-height: $input-btn-line-height !default;
$btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;
$btn-line-height-sm: $input-btn-line-height-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: 1.5rem !default;
$btn-font-size-lg: $input-btn-font-size-lg !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;

$btn-border-width: $input-btn-border-width !default;

$btn-font-weight: $font-weight-normal !default;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075) !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: 0.65 !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125) !default;

$btn-link-color: $link-color !default;
$btn-link-hover-color: $link-hover-color !default;
$btn-link-disabled-color: $gray-600 !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-sm: $border-radius !default;
$btn-border-radius-lg: $border-radius-lg !default;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$btn-hover-bg-shade-amount: 15% !default;
$btn-hover-bg-tint-amount: 15% !default;
$btn-hover-border-shade-amount: 20% !default;
$btn-hover-border-tint-amount: 10% !default;
$btn-active-bg-shade-amount: 20% !default;
$btn-active-bg-tint-amount: 20% !default;
$btn-active-border-shade-amount: 25% !default;
$btn-active-border-tint-amount: 10% !default;
// scss-docs-end btn-variables

// Forms

$form-group-margin-bottom: 1rem;

// scss-docs-start form-text-variables
$form-text-margin-top: 0.25rem !default;
$form-text-font-size: $small-font-size !default;
$form-text-font-style: null !default;
$form-text-font-weight: null !default;
$form-text-color: $text-muted !default;
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-margin-bottom: 0.25rem !default;
$form-label-font-size: null !default;
$form-label-font-style: null !default;
$form-label-font-weight: $font-weight-medium !default;
$form-label-color: $body-color !default;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-font-family: $input-btn-font-family !default;
$input-font-size: $input-btn-font-size !default;
$input-font-weight: $font-weight-base !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-font-size-sm: $input-btn-font-size-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-font-size-lg: $input-btn-font-size-lg !default;

$input-bg: $body-bg !default;
$input-disabled-bg: $gray-200 !default;
$input-disabled-border-color: null !default;

$input-color: $body-color !default;
$input-border-color: $gray-300 !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: $box-shadow-inset !default;

$input-border-radius: $border-radius !default;
$input-border-radius-sm: $border-radius-sm !default;
$input-border-radius-lg: $border-radius-lg !default;

$input-focus-bg: $input-bg !default;
//$input-focus-border-color: tint-color($component-active-bg, 50%) !default;
$input-focus-border-color: $gray-400 !default;
$input-focus-color: $input-color !default;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$input-filled-bg: $gray-100 !default;
$input-filled-border-color: $input-filled-bg !default;
$input-filled-disabled-bg: $gray-50 !default;
$input-filled-disabled-border-color: null !default;
$input-filled-focus-bg: shade-color($gray-100, 2%) !default;
$input-filled-focus-border-color: $input-filled-focus-bg !default;

$input-placeholder-color: $gray-400 !default;
$input-plaintext-color: $body-color !default;

$input-height-border: $input-border-width * 2 !default;

$input-height-inner: add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half: add($input-line-height * 0.5em, $input-padding-y) !default;
$input-height-inner-quarter: add($input-line-height * 0.25em, $input-padding-y * 0.5) !default;

$input-height: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm: add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg: add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

$input-transition: border-color 0.15s ease-in-out, background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$form-color-width: 3rem !default;
// scss-docs-end form-input-variables

$form-input-date-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill='#{$text-muted}'><path d='M0 0h24v24H0z' fill='none'/> <path d='M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z'/></svg>") !default;

// scss-docs-start form-check-variables
$form-check-input-width: 1.25em !default;
$form-check-min-height: $font-size-base * $line-height-base !default;
$form-check-padding-start: $form-check-input-width + 0.5em !default;
$form-check-margin-bottom: 0.125rem !default;
$form-check-label-font-size: null !default;
$form-check-label-font-size-sm: $font-size-sm !default;
$form-check-label-color: null !default;
$form-check-label-cursor: null !default;
$form-check-transition: null !default;

$form-check-input-width-sm: 1em !default;
$form-check-padding-start-sm: $form-check-input-width-sm + 0.5em !default;
$form-check-input-width-lg: 1.375em !default;
$form-check-padding-start-lg: $form-check-input-width-lg + 0.5em !default;

$form-check-input-active-filter: brightness(90%) !default;

$form-check-input-bg: $input-bg !default;
$form-check-input-border: 1px solid rgba($black, 0.25) !default;
$form-check-input-border-radius: $border-radius-sm !default;
$form-check-radio-border-radius: 50% !default;
$form-check-input-focus-border: $input-focus-border-color !default;
$form-check-input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$form-check-input-checked-color: $component-active-color !default;
$form-check-input-checked-bg-color: $component-active-bg !default;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color !default;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>") !default;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

$form-check-input-indeterminate-color: $component-active-color !default;
$form-check-input-indeterminate-bg-color: $component-active-bg !default;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color !default;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;

$form-check-input-disabled-opacity: 0.5 !default;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity !default;
$form-check-btn-check-disabled-opacity: $btn-disabled-opacity !default;

$form-check-inline-margin-end: 1rem !default;
// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$form-switch-color: rgba($black, 0.25) !default;
$form-switch-width: 2.5em !default;
$form-switch-padding-start: $form-switch-width + 0.5em !default;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;
$form-switch-border-radius: $form-switch-width !default;
$form-switch-transition: background-position 0.15s ease-in-out !default;

$form-switch-width-sm: 2em !default;
$form-switch-padding-start-sm: $form-switch-width-sm + 0.5em !default;
$form-switch-width-lg: 3em !default;
$form-switch-padding-start-lg: $form-switch-width-lg + 0.5em !default;

$form-switch-focus-color: $input-focus-border-color !default;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") !default;

$form-switch-checked-color: $component-active-color !default;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
$form-switch-checked-bg-position: right center !default;
// scss-docs-end form-switch-variables

// scss-docs-start input-group-variables
$input-group-addon-padding-y: $input-padding-y !default;
$input-group-addon-padding-x: $input-padding-x !default;
$input-group-addon-font-weight: $input-font-weight !default;
$input-group-addon-color: $input-color !default;
$input-group-addon-bg: $gray-100 !default;
$input-group-addon-border-color: $input-border-color !default;
// scss-docs-end input-group-variables

// scss-docs-start form-select-variables
$form-select-padding-y: $input-padding-y !default;
$form-select-padding-x: $input-padding-x !default;
$form-select-font-family: $input-font-family !default;
$form-select-font-size: $input-font-size !default;
$form-select-indicator-padding: $form-select-padding-x * 3 !default; // Extra padding for background-image
$form-select-font-weight: $input-font-weight !default;
$form-select-line-height: $input-line-height !default;
$form-select-color: $input-color !default;
$form-select-bg: $input-bg !default;
$form-select-disabled-color: null !default;
$form-select-disabled-bg: $gray-200 !default;
$form-select-disabled-border-color: $input-disabled-border-color !default;
$form-select-bg-position: right $form-select-padding-x center !default;
$form-select-bg-size: 16px 12px !default; // In pixels because image dimensions
$form-select-indicator-color: $gray-800 !default;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>") !default;

$form-select-feedback-icon-padding-end: $form-select-padding-x * 2.5 + $form-select-indicator-padding !default;
$form-select-feedback-icon-position: center right $form-select-indicator-padding !default;
$form-select-feedback-icon-size: $input-height-inner-half $input-height-inner-half !default;

$form-select-border-width: $input-border-width !default;
$form-select-border-color: $input-border-color !default;
$form-select-border-radius: $input-border-radius !default;
$form-select-box-shadow: $box-shadow-inset !default;

$form-select-focus-border-color: $input-focus-border-color !default;
$form-select-focus-width: $input-focus-width !default;
$form-select-focus-box-shadow: 0 0 0 $form-select-focus-width $input-btn-focus-color !default;

$form-select-padding-y-sm: $input-padding-y-sm !default;
$form-select-padding-x-sm: $input-padding-x-sm !default;
$form-select-font-size-sm: $input-font-size-sm !default;
$form-select-border-radius-sm: $input-border-radius-sm !default;

$form-select-padding-y-lg: $input-padding-y-lg !default;
$form-select-padding-x-lg: $input-padding-x-lg !default;
$form-select-font-size-lg: $input-font-size !default;
$form-select-border-radius-lg: $input-border-radius-lg !default;

$form-select-transition: $input-transition !default;
// scss-docs-end form-select-variables

// scss-docs-start form-range-variables
$form-range-track-width: 100% !default;
$form-range-track-height: 0.5rem !default;
$form-range-track-cursor: pointer !default;
$form-range-track-bg: $gray-300 !default;
$form-range-track-border-radius: 1rem !default;
$form-range-track-box-shadow: $box-shadow-inset !default;

$form-range-thumb-width: 1rem !default;
$form-range-thumb-height: $form-range-thumb-width !default;
$form-range-thumb-bg: $component-active-bg !default;
$form-range-thumb-border: 0 !default;
$form-range-thumb-border-radius: 1rem !default;
$form-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1) !default;
$form-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-focus-box-shadow !default;
$form-range-thumb-focus-box-shadow-width: $input-focus-width !default; // For focus box shadow issue in Edge
$form-range-thumb-active-bg: tint-color($component-active-bg, 70%) !default;
$form-range-thumb-disabled-bg: $gray-500 !default;
$form-range-thumb-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;
// scss-docs-end form-range-variables

// scss-docs-start form-file-variables
$form-file-button-color: $input-color !default;
$form-file-button-bg: $input-group-addon-bg !default;
$form-file-button-hover-bg: shade-color($form-file-button-bg, 5%) !default;
// scss-docs-end form-file-variables

// scss-docs-start form-floating-variables
$form-floating-height: add(3.5rem, $input-height-border) !default;
$form-floating-line-height: 1.25 !default;
$form-floating-padding-x: $input-padding-x !default;
$form-floating-padding-y: 1rem !default;
$form-floating-input-padding-t: 1.625rem !default;
$form-floating-input-padding-b: 0.625rem !default;
$form-floating-label-opacity: 0.65 !default;
$form-floating-label-transform: scale(0.8) translateY(-0.5rem) translateX(0.15rem) !default;
$form-floating-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out !default;
// scss-docs-end form-floating-variables

// Form validation

// scss-docs-start form-feedback-variables
$form-feedback-margin-top: $form-text-margin-top !default;
$form-feedback-font-size: $form-text-font-size !default;
$form-feedback-font-style: $form-text-font-style !default;
$form-feedback-valid-color: $success !default;
$form-feedback-invalid-color: $danger !default;

$form-feedback-icon-valid-color: $form-feedback-valid-color !default;
//$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$form-feedback-icon-valid-color}'><path d='M0 0h24v24H0z' fill='none'/><path d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z'/></svg>") !default;
$form-feedback-icon-invalid-color: $form-feedback-invalid-color !default;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;
// scss-docs-end form-feedback-variables

// scss-docs-start form-validation-states
$form-validation-states: (
    "valid": (
        "color": $form-feedback-valid-color,
        "icon": $form-feedback-icon-valid,
    ),
    "invalid": (
        "color": $form-feedback-invalid-color,
        "icon": $form-feedback-icon-invalid,
    ),
) !default;
// scss-docs-end form-validation-states

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-offcanvas-backdrop: 1040 !default;
$zindex-offcanvas: 1045 !default;
$zindex-modal-backdrop: 1050 !default;
$zindex-modal: 1055 !default;
$zindex-popover: 1070 !default;
$zindex-tooltip: 1080 !default;
// scss-docs-end zindex-stack

// Navs

// scss-docs-start nav-variables
$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-font-size: null !default;
$nav-link-font-weight: null !default;
$nav-link-color: $body-color !default;
$nav-link-hover-color: shift-color($body-color, $link-shade-percentage) !default;
$nav-link-active-color: $primary !default;
$nav-link-active-font-weight: $font-weight-medium !default;
$nav-link-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !default;
$nav-link-disabled-color: $gray-500 !default;

$nav-tabs-link-color: $gray-700 !default;
$nav-tabs-border-color: $gray-200 !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $primary !default;
$nav-tabs-link-active-font-weight: $font-weight-medium !default;
$nav-tabs-link-active-bg: transparent !default;
$nav-tabs-link-active-border-width: 2px !default;
$nav-tabs-link-active-border-color: $primary !default;

$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $component-active-bg !default;

// scss-docs-end nav-variables

// Navbar

// scss-docs-start navbar-variables
$navbar-height: 56px !default;

$navbar-padding-y: $spacer * 0.5 !default;
$navbar-padding-x: null !default;

$navbar-nav-item-margin-x: $spacer * 1.25 !default;
$navbar-nav-link-padding-x: $spacer * 1.25 !default;

$navbar-brand-font-size: $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) * 0.5 !default;
$navbar-brand-margin-end: 1rem !default;

$navbar-toggler-padding-y: 0.25rem !default;
$navbar-toggler-padding-x: 0.75rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;
$navbar-toggler-focus-width: $btn-focus-width !default;
$navbar-toggler-transition: box-shadow 0.15s ease-in-out !default;
// scss-docs-end navbar-variables

// scss-docs-start navbar-theme-variables
$navbar-dark-color: rgba($white, 0.75) !default;
$navbar-dark-hover-color: rgba($white, 1) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color: rgba($white, 0.1) !default;

$navbar-light-color: rgba($gray-900, 0.7) !default;
$navbar-light-hover-color: rgba($gray-900, 0.85) !default;
$navbar-light-active-color: $primary !default;
$navbar-light-disabled-color: rgba($gray-900, 0.3) !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($gray-900, 0.1) !default;

$navbar-light-brand-color: $gray-900 !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;
$navbar-dark-brand-color: $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color: $navbar-dark-active-color !default;
// scss-docs-end navbar-theme-variables

// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-min-width: 10rem !default;
$dropdown-padding-x: 0 !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-font-size: $font-size-base !default;
$dropdown-color: $body-color !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;
$dropdown-border-radius: $border-radius !default;
$dropdown-border-width: $border-width !default;
$dropdown-inner-border-radius: subtract($dropdown-border-radius, $dropdown-border-width) !default;
$dropdown-divider-bg: $dropdown-border-color !default;
$dropdown-divider-margin-y: $spacer * 0.5 !default;
$dropdown-box-shadow: $box-shadow !default;

$dropdown-link-color: $gray-700 !default;
$dropdown-link-hover-color: shade-color($dropdown-link-color, 10%) !default;
$dropdown-link-hover-bg: $gray-100 !default;

$dropdown-link-active-color: $component-active-color-light !default;
$dropdown-link-active-bg: $component-active-bg-light !default;

$dropdown-link-disabled-color: $gray-500 !default;

$dropdown-item-padding-y: $spacer * 0.5 !default;
$dropdown-item-padding-x: $spacer !default;

$dropdown-header-color: $gray-600 !default;
$dropdown-header-padding: $dropdown-padding-y $dropdown-item-padding-x !default;

$dropdown-toggle-arrow-color: inherit;
$dropdown-toggle-arrow: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24' fill='#{$dropdown-toggle-arrow-color}'><path d='M24 24H0V0h24v24z' fill='#{transparent}' /><path d='M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z' /></svg>") !default;

// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
$dropdown-dark-color: $gray-300 !default;
$dropdown-dark-bg: $gray-800 !default;
$dropdown-dark-border-color: $dropdown-border-color !default;
$dropdown-dark-divider-bg: $dropdown-divider-bg !default;
$dropdown-dark-box-shadow: null !default;
$dropdown-dark-link-color: $dropdown-dark-color !default;
$dropdown-dark-link-hover-color: $white !default;
$dropdown-dark-link-hover-bg: rgba($white, 0.15) !default;
$dropdown-dark-link-active-color: $dropdown-link-active-color !default;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg !default;
$dropdown-dark-link-disabled-color: $gray-500 !default;
$dropdown-dark-header-color: $gray-500 !default;
// scss-docs-end dropdown-dark-variables

// Pagination

// scss-docs-start pagination-variables
$pagination-padding-y: 0.375rem !default;
$pagination-padding-x: 0.75rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;

$pagination-color: $link-color !default;
$pagination-bg: $white !default;
$pagination-border-width: $border-width !default;
$pagination-border-radius: $border-radius !default;
$pagination-margin-start: -$pagination-border-width !default;
$pagination-border-color: $gray-300 !default;

$pagination-focus-color: $link-hover-color !default;
$pagination-focus-bg: $gray-200 !default;
$pagination-focus-box-shadow: $input-btn-focus-box-shadow !default;
$pagination-focus-outline: 0 !default;

$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-200 !default;
$pagination-hover-border-color: $gray-300 !default;

$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;

$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: $gray-300 !default;

$pagination-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$pagination-border-radius-sm: $border-radius-sm !default;
$pagination-border-radius-lg: $border-radius-lg !default;
// scss-docs-end pagination-variables

// Placeholders

// scss-docs-start placeholders
$placeholder-opacity-max: 0.5 !default;
$placeholder-opacity-min: 0.2 !default;
// scss-docs-end placeholders

// Cards

// scss-docs-start card-variables
$card-spacer-y: $spacer !default;
$card-spacer-x: $spacer !default;
$card-spacer-y-lg: $spacer * 1.5 !default;
$card-spacer-x-lg: $spacer * 1.5 !default;
$card-title-spacer-y: $spacer * 0.5 !default;
$card-title-spacer-y-lg: $spacer !default;
$card-border-width: $border-width !default;
$card-border-color: rgba($gray-700, 0.125) !default;
$card-border-radius: $border-radius !default;
$card-box-shadow: null !default;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width) !default;
$card-cap-padding-y: $card-spacer-y * 0.75 !default;
$card-cap-padding-x: $card-spacer-x !default;
$card-cap-padding-y-lg: $spacer !default;
$card-cap-padding-x-lg: $card-spacer-x-lg !default;
$card-cap-bg: rgba($gray-700, 0.03) !default;
$card-cap-color: null !default;
$card-height: null !default;
$card-color: null !default;
$card-bg: $white !default;
$card-img-overlay-padding: $spacer !default;
// $card-group-margin: $grid-gutter-width * 0.5 !default;
// scss-docs-end card-variables

// Accordion

// scss-docs-start accordion-variables
$accordion-padding-y: 1.25rem !default;
$accordion-padding-x: 1.25rem !default;
$accordion-color: $body-color !default;
$accordion-bg: $body-bg !default;
$accordion-border-width: $border-width !default;
$accordion-border-color: $border-color !default;
$accordion-border-radius: $border-radius !default;
$accordion-inner-border-radius: subtract($accordion-border-radius, $accordion-border-width) !default;

$accordion-body-padding-y: $accordion-padding-y !default;
$accordion-body-padding-x: $accordion-padding-x !default;

$accordion-button-padding-y: $accordion-padding-y !default;
$accordion-button-padding-x: $accordion-padding-x !default;
$accordion-button-color: $accordion-color !default;
$accordion-button-bg: $accordion-bg !default;
$accordion-transition: $btn-transition, border-radius 0.15s ease !default;
$accordion-button-active-bg: tint-color($component-active-bg, 90%) !default;
$accordion-button-active-color: shade-color($primary, 10%) !default;

$accordion-button-focus-border-color: $input-focus-border-color !default;
$accordion-button-focus-box-shadow: $btn-focus-box-shadow !default;

$accordion-icon-width: 1.25rem !default;
$accordion-icon-color: $accordion-button-color !default;
$accordion-icon-active-color: $accordion-button-color !default;
$accordion-icon-transition: transform 0.2s ease-in-out !default;
$accordion-icon-transform: rotate(-180deg) !default;

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' clip-rule='evenodd' d='M40.737 17.737a1.786 1.786 0 010 2.526l-14.9 14.9a2.143 2.143 0 01-3.03 0l-14.9-14.9a1.786 1.786 0 012.526-2.526l13.89 13.89 13.889-13.89a1.786 1.786 0 012.525 0z'/></svg>") !default;
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' clip-rule='evenodd' d='M40.737 17.737a1.786 1.786 0 010 2.526l-14.9 14.9a2.143 2.143 0 01-3.03 0l-14.9-14.9a1.786 1.786 0 012.526-2.526l13.89 13.89 13.889-13.89a1.786 1.786 0 012.525 0z'/></svg>") !default;

// Tooltips

// scss-docs-start tooltip-variables
$tooltip-font-size: $font-size-sm !default;
$tooltip-max-width: 200px !default;
$tooltip-color: $white !default;
$tooltip-bg: $black !default;
$tooltip-border-radius: $border-radius !default;
$tooltip-opacity: 0.9 !default;
$tooltip-padding-y: $spacer * 0.25 !default;
$tooltip-padding-x: $spacer * 0.5 !default;
$tooltip-margin: 0 !default;

$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: $tooltip-bg !default;
// scss-docs-end tooltip-variables

// Form tooltips must come after regular tooltips
// scss-docs-start tooltip-feedback-variables
$form-feedback-tooltip-padding-y: $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x: $tooltip-padding-x !default;
$form-feedback-tooltip-font-size: $tooltip-font-size !default;
$form-feedback-tooltip-line-height: null !default;
$form-feedback-tooltip-opacity: $tooltip-opacity !default;
$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;
// scss-docs-end tooltip-feedback-variables

// Popovers

// scss-docs-start popover-variables
$popover-font-size: $font-size-sm !default;
$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-width: $border-width !default;
$popover-border-color: rgba($black, 0.2) !default;
$popover-border-radius: $border-radius-lg !default;
$popover-inner-border-radius: subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow: $box-shadow !default;

$popover-header-bg: shade-color($popover-bg, 6%) !default;
$popover-header-color: $headings-color !default;
$popover-header-padding-y: 0.5rem !default;
$popover-header-padding-x: $spacer !default;

$popover-body-color: $body-color !default;
$popover-body-padding-y: $spacer !default;
$popover-body-padding-x: $spacer !default;

$popover-arrow-width: 1rem !default;
$popover-arrow-height: 0.5rem !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-color: fade-in($popover-border-color, 0.05) !default;
// scss-docs-end popover-variables

// Toasts

// scss-docs-start toast-variables
$toast-max-width: 350px !default;
$toast-padding-x: 0.75rem !default;
$toast-padding-y: 0.5rem !default;
$toast-font-size: 0.875rem !default;
$toast-color: null !default;
$toast-background-color: rgba($white, 0.85) !default;
$toast-border-width: 1px !default;
$toast-border-color: rgba($black, 0.1) !default;
$toast-border-radius: $border-radius !default;
$toast-box-shadow: $box-shadow !default;
$toast-spacing: $container-padding-x !default;

$toast-header-color: $gray-600 !default;
$toast-header-background-color: rgba($white, 0.85) !default;
$toast-header-border-color: rgba($black, 0.05) !default;
// scss-docs-end toast-variables

// Badges

// scss-docs-start badge-variables
$badge-font-size: 0.75em !default;
$badge-font-weight: $font-weight-medium !default;
$badge-color: inherit !default;
$badge-padding-y: 0.45em !default;
$badge-padding-x: 0.75em !default;
$badge-border-radius: $border-radius-xs !default;
// scss-docs-end badge-variables

// Counters

// scss-docs-start badge-variables
$counter-font-size: 0.75em !default;
$counter-font-weight: $font-weight-normal !default;
$counter-color: inherit !default;
$counter-padding-y: 0.35em !default;
$counter-padding-x: 0.65em !default;
$counter-border-radius: $border-radius-pill !default;
// scss-docs-end badge-variables

// Chips

// scss-docs-start badge-variables
$chip-font-size: 85% !default;
$chip-font-weight: $font-weight-medium !default;
$chip-color: inherit !default;
$chip-padding-y: 0.375rem !default;
$chip-padding-x: 0.5rem !default;
$chip-border-radius: $border-radius-pill !default;
$chip-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out !default;
// scss-docs-end badge-variables

// Modals

$modal-inner-padding: $spacer * 1.25 !default;

$modal-dialog-margin: 0.5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-color: null !default;
$modal-content-bg: $white !default;
$modal-content-border-radius: $border-radius-lg !default;
$modal-content-box-shadow-xs: $box-shadow-sm !default;
$modal-content-box-shadow-sm-up: $box-shadow !default;

$modal-backdrop-bg: $gray-900 !default;
$modal-backdrop-opacity: 0.5 !default;

$modal-header-border-color: var(--#{$prefix}border-color) !default;
$modal-header-border-width: $border-width !default;
$modal-header-padding-y: $modal-inner-padding !default;
$modal-header-padding-x: $modal-inner-padding !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-footer-bg: null !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-footer-padding-y: $spacer !default;
$modal-footer-padding-x: $modal-inner-padding !default;
$modal-footer-padding: $modal-footer-padding-y $modal-footer-padding-x !default;
$modal-footer-gap: $spacer !default;

$modal-sm: 300px !default;
$modal-md: 500px !default;
$modal-lg: 800px !default;
$modal-xl: 1140px !default;

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform 0.3s ease-out !default;
$modal-scale-transform: scale(1.02) !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: $spacer !default;
$alert-padding-x: $spacer !default;
$alert-margin-bottom: 1rem !default;
$alert-border-radius: $border-radius !default;
$alert-link-font-weight: $font-weight-semibold !default;
$alert-bg-scale: -88% !default;
$alert-color-scale: 40% !default;
$alert-dismissible-padding-r: $alert-padding-x * 3 !default; // 3x covers width of x plus default padding on either side

// Progress bars

// scss-docs-start progress-variables
$progress-height: 0.625rem !default;
$progress-height-xs: 0.25rem !default;
$progress-height-sm: 0.5rem !default;
$progress-height-lg: 0.875rem !default;
$progress-font-size: $font-size-base * 0.75 !default;
$progress-bg: $gray-200 !default;
$progress-border-radius: $border-radius-pill !default;
$progress-box-shadow: $box-shadow-inset !default;
$progress-bar-color: $white !default;
$progress-bar-bg: $primary !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition: width 0.6s ease !default;
// scss-docs-end progress-variables

// List group

// scss-docs-start list-group-variables
$list-group-color: $gray-900 !default;
$list-group-bg: $white !default;
$list-group-border-color: rgba($black, 0.125) !default;
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius !default;

$list-group-item-padding-y: $spacer * 0.5 !default;
$list-group-item-padding-x: $spacer !default;
$list-group-item-bg-scale: -80% !default;
$list-group-item-color-scale: 40% !default;

$list-group-hover-bg: $gray-100 !default;
$list-group-active-color: $component-active-color-light !default;
$list-group-active-bg: $component-active-bg-light !default;
$list-group-active-border-color: $list-group-active-bg !default;

$list-group-disabled-color: $gray-600 !default;
$list-group-disabled-bg: $list-group-bg !default;

$list-group-action-color: $gray-700 !default;
$list-group-action-hover-color: $list-group-action-color !default;

$list-group-action-active-color: $body-color !default;
$list-group-action-active-bg: $gray-200 !default;
// scss-docs-end list-group-variables

// Image thumbnails

// scss-docs-start thumbnail-variables
$thumbnail-padding: 0.25rem !default;
$thumbnail-bg: $body-bg !default;
$thumbnail-border-width: $border-width !default;
$thumbnail-border-color: $gray-300 !default;
$thumbnail-border-radius: $border-radius !default;
$thumbnail-box-shadow: $box-shadow-sm !default;
// scss-docs-end thumbnail-variables

// Figures

// scss-docs-start figure-variables
$figure-caption-font-size: $small-font-size !default;
$figure-caption-color: $gray-600 !default;
// scss-docs-end figure-variables

// Breadcrumbs

// scss-docs-start breadcrumb-variables
$breadcrumb-font-size: $font-size-sm !default;
$breadcrumb-padding-y: 0 !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-item-padding-x: 0.5rem !default;
$breadcrumb-margin-bottom: 1rem !default;
$breadcrumb-bg: null !default;
$breadcrumb-link-color: $gray-900 !default;
$breadcrumb-link-hover-color: $gray-700 !default;
$breadcrumb-divider-color: $gray-400 !default;
$breadcrumb-active-color: $gray-600 !default;
$breadcrumb-divider: quote("/") !default;
$breadcrumb-divider-flipped: $breadcrumb-divider !default;
$breadcrumb-border-radius: null !default;

$breadcrumb-dark-link-color: white !default;
$breadcrumb-dark-link-hover-color: rgba($white, 0.75) !default;
$breadcrumb-dark-active-color: rgba($white, 0.55) !default;
$breadcrumb-dark-divider-color: rgba($white, 0.25) !default;
// scss-docs-end breadcrumb-variables

// Carousel

// scss-docs-start carousel-variables
$carousel-control-color: $white !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: 0.5 !default;
$carousel-control-hover-opacity: 0.9 !default;
$carousel-control-transition: opacity 0.15s ease !default;

$carousel-indicator-width: 30px !default;
$carousel-indicator-height: 3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer: 3px !default;
$carousel-indicator-opacity: 0.5 !default;
$carousel-indicator-active-bg: $white !default;
$carousel-indicator-active-opacity: 1 !default;
$carousel-indicator-transition: opacity 0.6s ease !default;

$carousel-caption-width: 70% !default;
$carousel-caption-color: $white !default;
$carousel-caption-padding-y: 1.25rem !default;
$carousel-caption-spacer: 1.25rem !default;

$carousel-control-icon-width: 2rem !default;

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;

$carousel-transition-duration: 0.6s !default;
$carousel-transition: transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

$carousel-dark-indicator-active-bg: $gray-900 !default;
$carousel-dark-caption-color: $gray-900 !default;
$carousel-dark-control-icon-filter: invert(1) grayscale(100) !default;
// scss-docs-end carousel-variables

// Spinners

// scss-docs-start spinner-variables
$spinner-width: 2rem !default;
$spinner-height: $spinner-width !default;
$spinner-vertical-align: -0.125em !default;
$spinner-border-width: 0.25em !default;
$spinner-animation-speed: 0.75s !default;

$spinner-width-sm: 1rem !default;
$spinner-height-sm: $spinner-width-sm !default;
$spinner-border-width-sm: 0.2em !default;
// scss-docs-end spinner-variables

// Circle Loader

// scss-docs-start spinner-variables
$circle-loader-width: 2rem !default;
$circle-loader-height: $circle-loader-width !default;

$circle-loader-width-lg: 3rem !default;
$circle-loader-height-lg: $circle-loader-width-lg !default;

$circle-loader-width-sm: 1.5rem !default;
$circle-loader-height-sm: $circle-loader-width-sm !default;

$circle-loader-width-xs: 1rem !default;
$circle-loader-height-xs: $circle-loader-width-xs !default;
// scss-docs-end spinner-variables

// Close

// scss-docs-start close-variables
$btn-close-width: 1em !default;
$btn-close-height: $btn-close-width !default;
$btn-close-padding-x: 0.25em !default;
$btn-close-padding-y: $btn-close-padding-x !default;
$btn-close-color: $black !default;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;
$btn-close-focus-shadow: $input-btn-focus-box-shadow !default;
$btn-close-opacity: 0.5 !default;
$btn-close-hover-opacity: 0.75 !default;
$btn-close-focus-opacity: 1 !default;
$btn-close-disabled-opacity: 0.25 !default;
$btn-close-white-filter: invert(1) grayscale(100%) brightness(200%) !default;
// scss-docs-end close-variables

// Offcanvas

// scss-docs-start offcanvas-variables
$offcanvas-inner-padding: 1rem !default;
$offcanvas-padding-y: $offcanvas-inner-padding !default;
$offcanvas-padding-x: $offcanvas-inner-padding !default;
$offcanvas-horizontal-width: 400px !default;
$offcanvas-vertical-height: 30vh !default;
$offcanvas-transition-duration: 0.3s !default;
//$offcanvas-border-color: var(--#{$prefix}border-color-translucent) !default;
$offcanvas-border-width: $border-width !default;
$offcanvas-title-line-height: $modal-title-line-height !default;
$offcanvas-bg-color: $modal-content-bg !default;
$offcanvas-color: $modal-content-color !default;
$offcanvas-box-shadow: $modal-content-box-shadow-xs !default;
$offcanvas-backdrop-bg: $modal-backdrop-bg !default;
$offcanvas-backdrop-opacity: $modal-backdrop-opacity !default;

$offcanvas-nav-link-height: 3.75rem !default;
$offcanvas-nav-link-padding-y: 0rem !default;
$offcanvas-nav-link-padding-x: 1rem !default;
$offcanvas-nav-link-font-size: null !default;
$offcanvas-nav-link-font-weight: $font-weight-base !default;
$offcanvas-nav-link-color: $body-color !default;
$offcanvas-nav-link-hover-color: shift-color($body-color, $link-shade-percentage) !default;
$offcanvas-nav-link-active-color: $primary !default;
$offcanvas-nav-link-active-font-weight: $font-weight-medium !default;
$offcanvas-nav-link-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !default;
$offcanvas-nav-link-disabled-color: $gray-500 !default;

// scss-docs-end offcanvas-variables

// Code

$code-font-size: $small-font-size !default;
$code-color: $pink !default;

$kbd-padding-y: 0.1875rem !default;
$kbd-padding-x: 0.375rem !default;
$kbd-font-size: $code-font-size !default;
$kbd-color: var(--#{$prefix}body-bg) !default;
$kbd-bg: var(--#{$prefix}body-color) !default;
$nested-kbd-font-weight: null !default;

$pre-color: null !default;

// Scrollbar

$scrollbar-bg-color: rgba($gray-50, 0) !default;
$scrollbar-thumb-color: rgba($gray-900, 0.14) !default;

// Entity Status Bar
//
// For each status-bar-button, define text, background, and border color.

// scss-docs-start status-bar-button-variables

$status-bar-button-height: 36px !default;
$status-bar-item-triangle-border: calc($status-bar-button-height / 2) !default;

$status-bar-button-font-family: $input-btn-font-family !default;
$status-bar-button-font-size: $btn-font-size-sm !default;
$status-bar-button-line-height: $input-btn-line-height !default;
$status-bar-button-white-space: nowrap !default; // Set to `nowrap` to prevent text wrapping

$status-bar-button-font-weight: $font-weight-normal !default;
$status-bar-button-focus-width: $input-btn-focus-width !default;
$status-bar-button-focus-box-shadow: $input-btn-focus-box-shadow !default;
$status-bar-button-disabled-opacity: 0.5 !default;

$status-bar-button-color: $body-color !default;
$status-bar-button-background: $gray-300 !default;
$status-bar-button-hover-color: $body-color !default;
$status-bar-button-hover-background: shade-color($status-bar-button-background, 5%) !default;

$status-bar-button-current-color: $white !default;
$status-bar-button-current-background: $primary-700 !default;
$status-bar-button-current-hover-color: $white !default;
$status-bar-button-current-hover-background: $status-bar-button-current-background !default;

$status-bar-button-completed-color: $white !default;
$status-bar-button-completed-background: tint-color($success, 5%) !default;
$status-bar-button-completed-hover-color: $white !default;
$status-bar-button-completed-hover-background: shade-color($status-bar-button-completed-background, 5%) !default;

// Allows for customizing button radius independently from global border radius
$status-bar-button-border-radius: $border-radius !default;

$status-bar-button-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !default;

// scss-docs-end status-bar-button-variables

// Header

// scss-docs-start header-variables
$header-topbar-height: 2.25rem;
$header-main-height: 4.5rem;
$header-main-mobile-height: 4rem;
$navbar-height: 3.5rem;
// scss-docs-end header-variables
