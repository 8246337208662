@import "../../../assets/scss/component-base.scss";

// Sections Home

.section-split-header {
    position: relative;
    width: 100%;
}

.section-split-header__content {
    //padding: 4rem 0;
    @include padding-top(4rem);
    @include padding-bottom(4rem);

    @include media-breakpoint-up(md) {
        width: 60%;
        //padding: 4rem 2rem 12rem 0;
        @include padding-top(4rem);
        @include padding-right(2rem);
        @include padding-bottom(12rem);
        @include padding-left(0);
    }
}

.section-split-header__img {
    position: relative;
    height: 200px;

    // Full width
    width: calc(100% + (1.25rem * 2));
    margin-left: -1.25rem;

    @include media-breakpoint-down(sm) {
        width: calc(100% + (1rem * 2));
        margin-left: -1rem;
    }

    @include media-breakpoint-up(md) {
        width: 40%;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
    }

    img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        filter: opacity(0.5);
        mix-blend-mode: luminosity;
    }
}
