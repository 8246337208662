.splashscreen {
    background-image: radial-gradient(#002b74, #001842);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 100000;
    opacity: 1;
    transition: opacity 0.6s;

    &.hidden {
        display: none;
        opacity: 0;
    }
    &.splashscreen-hidding {
        opacity: 0;
    }
    &.splashscreen-light {
        background: rgba(255, 255, 255, 0.6);
    }
}

.fade-in-image {
    animation: fadeIn 2s;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
