@import "../../../assets/scss/component-base.scss";

.tag-series {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.15em 0.3em;
    font-family: $font-family-lathes;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-size: 1em;
    font-weight: $font-weight-medium;
    line-height: 1;
    color: tint-color($primary-400, 15%);
    background-color: $primary-100;
    text-align: center;
    white-space: nowrap;
    border-radius: 6px;

    // + & {
    //     margin-right: 0.5rem;
    // }
}
