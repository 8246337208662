@import "../../assets/scss/component-base.scss";

.order-received-header {
    > .container {
        max-width: 900px;
    }
}

.order-received-details {
    > .container {
        max-width: 1024px;
    }
}

.icon-thank-you-page {
    background-color: $primary-50;
    border-radius: 50%;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;

    svg {
        width: 40px;
        height: 40px;
    }
}
