@import "../../../assets/scss/component-base.scss";

@include media-breakpoint-down(lg) {
    .app-loading-bar {
        top: auto !important;
        bottom: 0;
        height: 4px !important;
    }
    .app-loading-bar--shadow {
        top: 5px;
    }
}
