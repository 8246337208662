@import "../../assets/scss/component-base.scss";

.sidebar-filters {
    margin-top: 1.5rem;
    border-top: 1px solid $border-color;
    padding-top: 1.5rem;

    h3 {
        font-size: $h6-font-size;
        font-weight: $font-weight-bold;
        margin-bottom: 1.5rem;
    }
}

.filter-options {
    > a {
        color: inherit;

        &:hover,
        &:focus {
            color: $nav-link-hover-color;
        }
    }
    .form-check-input,
    .form-check-label {
        cursor: pointer;
    }
}

.offcanvas-filters {
    .sidebar-filters:first-child {
        border-top: 0;
        padding-top: 0;
        margin-top: 0;
    }
    .filter-options {
        .form-check {
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
        }
    }
}
