@import "../../../assets/scss/component-base.scss";

// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

%counter {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: $counter-padding-y $counter-padding-x;
    @include font-size($counter-font-size);
    font-weight: $counter-font-weight;
    line-height: 1;
    //color: $counter-color;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: $counter-border-radius;
    @include gradient-bg();
}

.counter {
    @extend %counter;

    // Empty counters collapse automatically
    &:empty {
        display: none;
    }
}

//
// Alternate Counter
//

// Solid (Default)
@each $color, $value in $badge-colors {
    .counter--#{$color} {
        @include badge-variant($value);
    }
}

// Light
@each $color, $value in $badge-colors {
    .counter--light-#{$color} {
        @include badge-light-variant($value);
    }
}

//
// Correct Secondary default colors
//

// .counter--light-secondary {
//     color: $body-color;
//     background-color: $gray-200;
// }

//
// Correct Success default colors
//

.counter--success {
    color: $white;
}

.counter--light-success {
    color: shade-color($success-600, 10%);
}

//
// Correct Warning default colors
//

.counter--warning {
    color: shade-color($warning-900, 30%);
    background-color: $warning-300;
}

.counter--light-warning {
    color: $warning-900;
    background-color: $warning-100;
}
