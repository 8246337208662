.fade {
    @include transition($transition-fade);

    &:not(.show) {
        opacity: 0;
    }
}

.collapse {
    &:not(.show) {
        display: none;
    }
}

.collapsing {
    height: 0;
    overflow: hidden;
    @include transition($transition-collapse);

    &.collapse-horizontal {
        width: 0;
        height: auto;
        @include transition($transition-collapse-width);
    }
}


.slide-down-enter {
    opacity: 0;
    max-height: 0;
    transition: .3s;
}
.slide-down-enter-active {
    opacity: 1;
    max-height: 99999px;
}
.slide-down-enter-done {

}

.slide-down-exit {
    opacity: 1;
    transition: .3s;
}
.slide-down-exit-active {
    opacity: 0;
    max-height: 0;
}
.slide-down-exit-done{
    display: none;
}