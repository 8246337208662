@import "../../assets/scss/component-base.scss";

.header-checkout {
    position: sticky;
    z-index: $zindex-sticky;
    left: 0;
    right: 0;
    top: 0;
    transition: all 0.3s ease-in-out;
}

.header-checkout-topbar {
    display: none;
    position: relative;
    background-color: $gray-200;
    height: $header-topbar-height;
}

.header-checkout-main {
    background: rgba($primary-800, 0.96);
    @include media-breakpoint-up(lg) {
        background: $gradient-primary-90-deg;
    }

    backdrop-filter: blur(2px);
    color: $white;
    height: $header-main-mobile-height;

    > .container {
        display: flex;
        align-items: center;
        height: 100%;
    }
}

.header-checkout-title {
    @include font-size($h5-font-size);
    font-weight: 500;

    @include media-breakpoint-up(lg) {
        @include font-size($h2-font-size);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @include media-breakpoint-down(lg) {
        margin-left: auto;
    }
}

//
// Responsive
//

@include media-breakpoint-up(lg) {
    .header-checkout {
        top: -$header-topbar-height;
    }
    .header-checkout-topbar {
        display: block;
    }
    .header-checkout-main {
        // height: $header-main-height;
        height: 5rem;
        border-top: 2px solid #e5e7eb; /** Alvaro: Ñapa para que se vea el loader en posición fixed **/
    }
    .btn-menu-toggle {
        display: none;
    }
}

@include media-breakpoint-down(lg) {
    .header-checkout-main {
        > .container {
            padding-left: 0.5rem;
            //padding-right: 0.5rem;
        }
    }
    .header-checkout-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .header-checkout-logo-img {
        width: 100px;
    }
}
