@import "../../assets/scss/component-base.scss";

.my-account-main-content {
    max-width: 1024px;
}
.card.card-shadow {
    //border: 0;
    height: 100%;
    box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);

    .card-title h2 {
        @include font-size(1.125rem);
        font-weight: 700;
        // font-family: $font-family-base;
        // letter-spacing: normal;
        // font-weight: $font-weight-semibold;
    }
    .card-body {
        position: relative;
        @include media-breakpoint-up(md) {
            @include padding(1.5rem);
        }
    }

    .icon-giant {
        width: 164px;
        height: 164px;
        fill: $gray-300;
    }
}

.data-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    padding: 1rem 0;

    font-size: $font-size-sm;

    .icon-svg {
        width: 20px;
        height: 20px;
        fill: $gray-400;
    }

    .label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: $text-muted;
    }
    .value {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-weight: $font-weight-medium;
    }
}
