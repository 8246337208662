@import "../../../assets/scss/component-base.scss";

.avatar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: $font-size-base;
    line-height: 1;
    border-radius: $border-radius;
    background-color: $gray-100;
    color: $gray-400;
    font-weight: $font-weight-normal;
    height: 2.5rem;
    width: 2.5rem;
    fill: $gray-400;
}

.avatar {
    svg {
        fill: currentColor;
        height: 1.5rem;
        width: 1.5rem;
    }
}

//
// Avatar Sizes
//

.avatar--xxs {
    height: 1.25rem;
    width: 1.25rem;
    font-size: $font-size-xs;

    svg {
        height: 1rem;
        width: 1rem;
    }
}

.avatar--xs {
    height: 1.5rem;
    width: 1.5rem;
    font-size: $font-size-xs;

    svg {
        height: 1rem;
        width: 1rem;
    }
}

.avatar--sm {
    height: 2rem;
    width: 2rem;
    font-size: $font-size-sm;

    svg {
        height: 1.25rem;
        width: 1.25rem;
    }
}

.avatar--lg {
    height: 3.5rem;
    width: 3.5rem;
}

//
// Avatar Variants
//

.rounded {
}

.rounded-circle {
}

//
// Avatar Item = Avatar + Text
//

.avatar-item {
    display: flex;
    align-items: center;
}
