@import "../../../assets/scss/component-base.scss";

.cart-line {
    position: relative;
    display: flex;
    gap: 0.75rem;
    padding: 1rem 0;
    border-bottom: 1px solid $border-color;
}

.cart-line__img {
    background-color: $gray-100;
    width: 90px;
    height: 90px;
    flex-shrink: 0;

    &.cart-line-old-main-image {
        img {
            mix-blend-mode: multiply;
        }
    }
}

.cart-line__info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.cart-line-title {
    color: inherit;
    font-weight: $font-weight-medium;
    line-height: 1.3;
    padding-right: 2.25rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &:hover,
    &:focus {
        color: inherit;
    }
}

.cart-line-sku {
    font-size: $font-size-sm;
}

// .cart-line-stock {
//     font-size: $font-size-xs;
//     margin-left: 0.5rem;
//     color: $success-700;
//     letter-spacing: 0.0375rem;

//     &:before {
//         content: "";
//         position: relative;
//         left: -0.5rem;
//         display: inline-block;
//         width: 0.5rem;
//         height: 0.5rem;
//         border-radius: 50%;
//         background-color: $success;
//     }
// }

.btn-cart-line-delete {
    position: absolute;
    top: 0.5rem;
    //right: -0.5rem;
    right: 0;
    opacity: 0.5;

    &:hover,
    &:focus {
        opacity: 1;
    }
}

.cart-list {
    .cart-line:last-child {
        border-bottom: 0;
    }
}

// Alvaro
// .cart-line{
//     transition: ease 0.3s;
//     overflow: hidden;
//     max-height: 99999px;
// }
// .cart-line.exit{
//     max-height: 0;
//     padding: 0;
//     opacity: 0;
//     position: relative;
// }
// .cart-line.exit:before{
//     content: "";
//     display: block;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     position: absolute;
//     z-index: 2;
//     background: rgba(255, 0, 0, 0.095);
// }

.cart-line-price {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
    del {
        color: $text-muted;
        text-align: right;
        // margin-right: 0.5rem;
        font-size: 0.7em;
        line-height: 1em;
    }

    ins {
        color: $danger;
        font-weight: $font-weight-semibold;
        text-decoration: none;
        text-align: right;
        line-height: 1em;
    }
}
