@import "../../assets/scss/component-base.scss";

.shopping-cart-layout {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }
}

.shopping-cart-layout__main,
.shopping-cart-layout__aside {
    flex-grow: 1;
}

.shopping-cart-layout__main {
    position: relative;
    width: 100%;
    min-height: 300px;
    padding: 2rem 0rem;

    @include media-breakpoint-up(lg) {
        min-height: 600px;
        padding: 4rem 4rem 2rem 0;
    }
}

.shopping-cart-layout__main {
    .cart-list {
        max-width: 1000px;
    }
}

.shopping-cart-page {
    overflow-x: hidden;
}

@include media-breakpoint-up(sm) {
    .shopping-cart-page {
        .cart-line {
            gap: 1.5rem;
        }

        .cart-line__img {
            width: 120px;
            height: 120px;
        }
        .cart-line-title {
            font-size: 1.125rem;
        }
    }
}

.shopping-cart-layout__aside {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $gray-100;
    display: flex;
    flex-direction: column;
    gap: map-get($spacers, 4);

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100vw;
        background-color: $gray-100;
        z-index: -1;

        @include media-breakpoint-down(lg) {
            left: -50%;
            width: 200vw;
        }
    }
    padding: 2rem 0;

    @include media-breakpoint-up(lg) {
        width: 40%;
        min-width: 25rem;
        padding: 4rem 0 4rem 2.5rem;
    }

    @include media-breakpoint-up(xl) {
        padding-left: 3rem;
    }
    @include media-breakpoint-up(xxl) {
        padding-left: 4rem;
    }
}

.cart-total {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .cart-total__line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .label {
            font-weight: $font-weight-medium;
        }
    }

    .cart-total__line--grand-total {
        .label,
        .value {
            font-weight: $font-weight-semibold;
            font-size: $font-size-lg;
        }
        .value {
            color: $gray-900;
        }
    }
}
