@import "../../../assets/scss/component-base.scss";

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: $breadcrumb-padding-y $breadcrumb-padding-x;
    margin-bottom: $breadcrumb-margin-bottom;
    @include font-size($breadcrumb-font-size);
    list-style: none;
    background-color: $breadcrumb-bg;
    @include border-radius($breadcrumb-border-radius);
}

.breadcrumb-item {
    // The separator between breadcrumbs (by default, a forward-slash: "/")
    + .breadcrumb-item {
        padding-left: $breadcrumb-item-padding-x;

        &::before {
            float: left; // Suppress inline spacings and underlining of the separator
            padding-right: $breadcrumb-item-padding-x;
            color: $breadcrumb-divider-color;
            content: var(--#{$prefix}breadcrumb-divider, escape-svg($breadcrumb-divider)) #{"/* rtl:"} var(
                    --#{$prefix}breadcrumb-divider,
                    escape-svg($breadcrumb-divider-flipped)
                ) #{"*/"};
        }
    }

    &.active {
        color: $breadcrumb-active-color;
        @include text-truncate();
    }

    a {
        color: $breadcrumb-link-color;

        &:hover,
        &:focus {
            color: $breadcrumb-link-hover-color;
        }
    }
}

// White links against a dark background
.breadcrumb--dark {
    .breadcrumb-item {
        + .breadcrumb-item {
            &::before {
                color: $breadcrumb-dark-divider-color;
            }
        }

        &.active {
            color: $breadcrumb-dark-active-color;
        }

        a {
            color: $breadcrumb-dark-link-color;

            &:hover,
            &:focus {
                color: $breadcrumb-dark-link-hover-color;
            }
        }
    }
}

// Alvaro
.breadcrumb {
    flex-wrap: nowrap;
    position: relative;
    scroll-behavior: smooth;
    overflow-x: auto;
    // padding-left: 1.25rem;
    // padding-right: 1.25rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    white-space: nowrap;
}
.breadcrumb-item {
    + .breadcrumb-item {
        &::before {
            float: none;
        }
    }
}

.breadcrumb::-webkit-scrollbar {
    width: 0;
    height: 0;
    -webkit-transition: width 0.3s;
    transition: width 0.3s;
}
