@import "../../../assets/scss/component-base.scss";

.page-masthead {
    position: relative;
    //padding: 6rem 0;
    //min-height: 30vh;
}

.page-masthead--bg-dark {
    color: $white;
    background-color: $primary-900;
    h1 {
        color: $white;
        text-transform: uppercase;
        margin-bottom: 0;

        & + p {
            margin-bottom: 1rem;
        }
    }
    // background-color: darken($primary-900, 4%);
}

.page-masthead__content {
    z-index: 1;
    position: relative;
    //padding: 5rem 0;
    padding: 3rem 0;
    min-height: 22vh;
    display: flex;
    align-items: center;
}

.page-masthead__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: #000c21;
        background: linear-gradient(180deg, rgba($primary-900, 0.5) 25%, rgba($primary-900, 0.5) 100%);
        opacity: 1;
    }
}

// Breadcrumbs

.page-masthead .breadcrumbs-wrapper {
    position: absolute;
    top: 1rem;
    width: 100%;
    @include media-breakpoint-down(lg) {
        left: 0;
        padding-left: 1rem;
    }
}
