// @font-face {
//     font-family: "Inter";
//     src: url("../../fonts/inter/Inter-Regular.eot");
//     src: url("../../fonts/inter/Inter-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/inter/Inter-Regular.woff2") format("woff2"),
//         url("../../fonts/inter/Inter-Regular.woff") format("woff"), url("../../fonts/inter/Inter-Regular.svg#Inter-Regular") format("svg");
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }
// @font-face {
//     font-family: "Inter";
//     src: url("../../fonts/inter/Inter-Medium.eot");
//     src: url("../../fonts/inter/Inter-Medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/inter/Inter-Medium.woff2") format("woff2"),
//         url("../../fonts/inter/Inter-Medium.woff") format("woff"), url("../../fonts/inter/Inter-Medium.svg#Inter-Medium") format("svg");
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }
// @font-face {
//     font-family: "Inter";
//     src: url("../../fonts/inter/Inter-SemiBold.eot");
//     src: url("../../fonts/inter/Inter-SemiBold.eot?#iefix") format("embedded-opentype"), url("../../fonts/inter/Inter-SemiBold.woff2") format("woff2"),
//         url("../../fonts/inter/Inter-SemiBold.woff") format("woff"), url("../../fonts/inter/Inter-SemiBold.svg#Inter-SemiBold") format("svg");
//     font-weight: 600;
//     font-style: normal;
//     font-display: swap;
// }
// @font-face {
//     font-family: "Inter";
//     src: url("../../fonts/inter/Inter-Bold.eot");
//     src: url("../../fonts/inter/Inter-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/inter/Inter-Bold.woff2") format("woff2"),
//         url("../../fonts/inter/Inter-Bold.woff") format("woff"), url("../../fonts/inter/Inter-Bold.svg#Inter-Bold") format("svg");
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Panton";
//     src: url("../../fonts/panton/Panton-ExtraLight.eot");
//     src: local("Panton ExtraLight"), local("Panton-ExtraLight"), url("../../fonts/panton/Panton-ExtraLight.eot?#iefix") format("embedded-opentype"),
//         url("../../fonts/panton/Panton-ExtraLight.woff2") format("woff2"), url("../../fonts/panton/Panton-ExtraLight.woff") format("woff"),
//         url("../../fonts/panton/Panton-ExtraLight.ttf") format("truetype");
//     font-weight: 200;
//     font-style: normal;
// }

// @font-face {
//     font-family: "Panton";
//     src: url("../../fonts/panton/Panton-ExtraLightItalic.eot");
//     src: local("Panton ExtraLight Italic"), local("Panton-ExtraLightItalic"),
//         url("../../fonts/panton/Panton-ExtraLightItalic.eot?#iefix") format("embedded-opentype"),
//         url("../../fonts/panton/Panton-ExtraLightItalic.woff2") format("woff2"), url("../../fonts/panton/Panton-ExtraLightItalic.woff") format("woff"),
//         url("../../fonts/panton/Panton-ExtraLightItalic.ttf") format("truetype");
//     font-weight: 200;
//     font-style: italic;
// }

// @font-face {
//     font-family: "Panton";
//     src: url("../../fonts/panton/Panton-Light.eot");
//     src: local("Panton Light"), local("Panton-Light"), url("../../fonts/panton/Panton-Light.eot?#iefix") format("embedded-opentype"),
//         url("../../fonts/panton/Panton-Light.woff2") format("woff2"), url("../../fonts/panton/Panton-Light.woff") format("woff"),
//         url("../../fonts/panton/Panton-Light.ttf") format("truetype");
//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
//     font-family: "Panton";
//     src: url("../../fonts/panton/Panton-LightItalic.eot");
//     src: local("Panton Light Italic"), local("Panton-LightItalic"), url("../../fonts/panton/Panton-LightItalic.eot?#iefix") format("embedded-opentype"),
//         url("../../fonts/panton/Panton-LightItalic.woff2") format("woff2"), url("../../fonts/panton/Panton-LightItalic.woff") format("woff"),
//         url("../../fonts/panton/Panton-LightItalic.ttf") format("truetype");
//     font-weight: 300;
//     font-style: italic;
// }

@font-face {
    font-family: "Panton";
    src: url("../../fonts/panton/Panton-Regular.eot");
    src: local("Panton"), local("Panton-Regular"), url("../../fonts/panton/Panton-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/panton/Panton-Regular.woff2") format("woff2"), url("../../fonts/panton/Panton-Regular.woff") format("woff"),
        url("../../fonts/panton/Panton-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Panton";
    src: url("../../fonts/panton/Panton-RegularItalic.eot");
    src: local("Panton Regular Italic"), local("Panton-RegularItalic"), url("../../fonts/panton/Panton-RegularItalic.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/panton/Panton-RegularItalic.woff2") format("woff2"), url("../../fonts/panton/Panton-RegularItalic.woff") format("woff"),
        url("../../fonts/panton/Panton-RegularItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Panton";
    src: url("../../fonts/panton/Panton-SemiBold.eot");
    src: local("Panton SemiBold"), local("Panton-SemiBold"), url("../../fonts/panton/Panton-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/panton/Panton-SemiBold.woff2") format("woff2"), url("../../fonts/panton/Panton-SemiBold.woff") format("woff"),
        url("../../fonts/panton/Panton-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Panton";
    src: url("../../fonts/panton/Panton-SemiBoldItalic.eot");
    src: local("Panton SemiBold Italic"), local("Panton-SemiBoldItalic"), url("../../fonts/panton/Panton-SemiBoldItalic.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/panton/Panton-SemiBoldItalic.woff2") format("woff2"), url("../../fonts/panton/Panton-SemiBoldItalic.woff") format("woff"),
        url("../../fonts/panton/Panton-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Panton";
    src: url("../../fonts/panton/Panton-Bold.eot");
    src: local("Panton Bold"), local("Panton-Bold"), url("../../fonts/panton/Panton-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/panton/Panton-Bold.woff2") format("woff2"), url("../../fonts/panton/Panton-Bold.woff") format("woff"),
        url("../../fonts/panton/Panton-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Panton";
    src: url("../../fonts/panton/Panton-BoldItalic.eot");
    src: local("Panton Bold Italic"), local("Panton-BoldItalic"), url("../../fonts/panton/Panton-BoldItalic.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/panton/Panton-BoldItalic.woff2") format("woff2"), url("../../fonts/panton/Panton-BoldItalic.woff") format("woff"),
        url("../../fonts/panton/Panton-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Panton";
    src: url("../../fonts/panton/Panton-ExtraBold.eot");
    src: local("Panton ExtraBold"), local("Panton-ExtraBold"), url("../../fonts/panton/Panton-ExtraBold.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/panton/Panton-ExtraBold.woff2") format("woff2"), url("../../fonts/panton/Panton-ExtraBold.woff") format("woff"),
        url("../../fonts/panton/Panton-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Panton";
    src: url("../../fonts/panton/Panton-ExtraBoldItalic.eot");
    src: local("Panton ExtraBold Italic"), local("Panton-ExtraBoldItalic"),
        url("../../fonts/panton/Panton-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/panton/Panton-ExtraBoldItalic.woff2") format("woff2"), url("../../fonts/panton/Panton-ExtraBoldItalic.woff") format("woff"),
        url("../../fonts/panton/Panton-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "Panton";
    src: url("../../fonts/panton/Panton-Black.eot");
    src: local("Panton Black"), local("Panton-Black"), url("../../fonts/panton/Panton-Black.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/panton/Panton-Black.woff2") format("woff2"), url("../../fonts/panton/Panton-Black.woff") format("woff"),
        url("../../fonts/panton/Panton-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Panton";
    src: url("../../fonts/panton/Panton-BlackItalic.eot");
    src: local("Panton Black Italic"), local("Panton-BlackItalic"), url("../../fonts/panton/Panton-BlackItalic.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/panton/Panton-BlackItalic.woff2") format("woff2"), url("../../fonts/panton/Panton-BlackItalic.woff") format("woff"),
        url("../../fonts/panton/Panton-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "1st Enterprises Expanded";
    src: url("../../fonts/1stenterprisesexpanded/1stEnterprisesExpanded.eot");
    src: url("../../fonts/1stenterprisesexpanded/1stEnterprisesExpanded.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/1stenterprisesexpanded/1stEnterprisesExpanded.woff2") format("woff2"),
        url("../../fonts/1stenterprisesexpanded/1stEnterprisesExpanded.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
