//
// Form input tel
//
//

.form-input-tel {
    .input-group__prefix > .dropdown,
    .input-group__suffix > .dropdown {
        margin-left: 0;
        padding-left: 0;
    }
    .dropdown-menu {
        .dropdown-toggle:not(.hidden-caret)::after {
            margin-left: 0.5rem;
        }
        max-height: 300px;
        overflow-y: auto;
        z-index: $zindex-dropdown;
    }
}
.input-tel-placeholder {
    background-color: $gray-100;
    width: 1rem;
    height: 0.75rem;
}
