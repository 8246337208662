// Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via scoped `:invalid` and `:valid`
// pseudo-classes but also includes `.is-invalid` and `.is-valid` classes for
// server-side validation.

// scss-docs-start form-validation-states-loop
@each $state, $data in $form-validation-states {
    @include form-validation-state($state, $data...);
}
// scss-docs-end form-validation-states-loop

//
// Override form validations "success" styles
// --------------------------------------------------

// .form-control.is-valid {
//     border-color: $input-border-color;
//     background-image: none;
//     padding-right: $input-padding-x;

//     &.form-control--sm {
//         padding-right: $input-padding-x-sm;
//     }

//     &.form-control--lg {
//         padding-right: $input-padding-x-lg;
//     }

//     &:focus {
//         border-color: $input-focus-border-color;
//         @if $enable-shadows {
//             @include box-shadow($input-box-shadow, $input-focus-box-shadow);
//         } @else {
//             // Avoid using mixin so we can pass custom focus shadow properly
//             box-shadow: $input-focus-box-shadow;
//         }
//     }

//     &.form-control--filled {
//         border-color: $input-filled-border-color;
//     }
// }

.form-input-group .form-control {
    &.is-valid,
    &.is-invalid {
        border-color: transparent;
        &:focus {
            border-color: transparent;
        }
    }

    // &.is-valid {
    //     ~ .fake-form-control {
    //         border-color: $input-border-color;
    //     }

    //     &:focus ~ .fake-form-control {
    //         border-color: $input-focus-border-color;
    //         @if $enable-shadows {
    //             @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    //         } @else {
    //             // Avoid using mixin so we can pass custom focus shadow properly
    //             box-shadow: $input-focus-box-shadow;
    //         }
    //     }

    //     &.form-control--filled {
    //         ~ .fake-form-control {
    //             border-color: $input-filled-border-color;
    //         }
    //         &:focus ~ .fake-form-control {
    //             border-color: $input-filled-focus-border-color;
    //             @if $enable-shadows {
    //                 @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    //             } @else {
    //                 // Avoid using mixin so we can pass custom focus shadow properly
    //                 box-shadow: $input-focus-box-shadow;
    //             }
    //         }
    //     }
    // }
}

.is-invalid {
    .invalid-feedback {
        display: block;
    }
}
