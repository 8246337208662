@import "../../../assets/scss/component-base.scss";

$quantity-input-width: 3.5rem;
$quantity-input-width-sm: 2.5rem;
$quantity-input-width-lg: 3.5rem;

.quantity-input-group {
    display: flex;

    .quantity-input-text {
        display: block;
        width: $quantity-input-width;
        @include font-size($input-font-size);
        font-weight: $input-font-weight;
        line-height: $input-line-height;
        text-align: center;
        color: $input-color;
        background-color: $input-bg;
        background-clip: padding-box;
        border: $input-border-width solid transparent;
        appearance: none; // Fix appearance for date inputs in Safari

        @include border-radius($input-border-radius, 0);
        @include box-shadow($input-box-shadow);
        @include transition($input-transition);

        &:focus {
            color: $input-focus-color;
            background-color: $gray-50;
            border-color: transparent;
            outline: 0;
            @if $enable-shadows {
                @include box-shadow($input-box-shadow, $input-focus-box-shadow);
            } @else {
                box-shadow: $input-focus-box-shadow;
            }
        }

        &:disabled,
        &[readonly] {
            // background-color: $input-disabled-bg;
            border-color: $input-disabled-border-color;
            // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
            opacity: 1;
        }
        &:disabled {
            cursor: not-allowed;
        }
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .btn {
        border-radius: $border-radius-pill;
    }
}

.quantity-input-group--sm {
    .quantity-input-text {
        width: $quantity-input-width-sm;
    }
}
