@import "../../assets/scss/component-base.scss";

.sidebar-layout {
    @include media-breakpoint-up(lg) {
        display: flex;
        gap: 2rem;

        .sidebar {
            min-width: 250px;
        }

        .main-content {
            flex-grow: 1;
        }
    }
    @include media-breakpoint-up(xl) {
        .sidebar {
            min-width: 300px;
        }
        gap: 3rem;
    }

    @include media-breakpoint-up(xxxl) {
        gap: 4rem;
    }
}

.product-list {
    .card-product {
        margin-bottom: 1.5rem;
    }
}
