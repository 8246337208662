// Container widths
//

.container {
    width: 100%;
    max-width: $container-max-width;
    margin-right: auto;
    margin-left: auto;
    @each $breakpoint, $container-padding-x in $container-paddings-x {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            padding-right: $container-padding-x;
            padding-left: $container-padding-x;
        }
    }
}

.container--lg {
    max-width: 992px;
}
