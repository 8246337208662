//
// Base styles
//

.form-input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap; // For form validation feedback
    align-items: stretch;
    width: 100%;

    > .form-control {
        position: relative; // For focus state's z-index
        flex: 1 1 auto;
        width: 1%;
        min-width: 0; // https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size
        background-color: transparent;
        border-color: transparent;
        z-index: 2;
    }

    // Bring the "active" form control to the top of surrounding elements
    > .form-control:focus {
        z-index: 3;
        border-color: transparent;
    }

    // Ensure buttons are always above inputs for more visually pleasing borders.
    // This isn't needed for `.form-input-group-text` since it shares the same border-color
    // as our inputs.
    .btn {
        position: relative;
        z-index: 2;

        &:focus {
            z-index: 3;
        }
    }

    svg:not(.btn *) {
        fill: $body-color;
        opacity: 0.25;
    }

    .input-group__prefix,
    .input-group__suffix {
        position: relative;
        z-index: 3;
    }
}

// Textual addons
//
// Serves as a catch-all element for any icon or text you wish
// to prepend or append to an input.

.input-group__prefix,
.input-group__suffix {
    display: flex;
    align-items: center;
    white-space: nowrap;

    > *:not(.btn) {
        padding: 0 0 0 $input-group-addon-padding-x;
    }

    > .btn {
        border: none;
        height: 100%;
    }
}

.input-group__prefix {
    > *:not(.btn) {
        padding: 0 0 0 $input-group-addon-padding-x;
    }
}

.input-group__suffix {
    > *:not(.btn) {
        padding: 0 $input-group-addon-padding-x 0 0;
    }
}

.input-group__suffix {
    .btn-clear {
        transition: $transition-fade;
        opacity: 0.25;
        &:hover {
            opacity: 0.35;
        }
    }
}

.input-group__prefix ~ .form-control {
    padding-left: 0.375rem;
    @include border-start-radius(0);
}
.input-group__suffix ~ .form-control {
    @include border-end-radius(0);
}

// Dropdown addons
//

.input-group__prefix,
.input-group__suffix {
    > .dropdown {
        height: 100%;
        margin-left: -$input-group-addon-padding-x;

        .dropdown-toggle {
            height: 100%;
        }
    }
}

.input-group__prefix {
    > .dropdown {
        .dropdown-toggle {
            padding-right: 0.5rem;
        }
    }
}

// Sizing
//
// Remix the default form control sizing classes into new ones for easier
// manipulation.

.form-input-group-lg > .form-control,
.form-input-group-lg > .input-group__prefix,
.form-input-group-lg > .input-group__suffix,
.form-input-group-lg > .btn {
    padding: $input-padding-y-lg $input-padding-x-lg;
    @include font-size($input-font-size-lg);
    @include border-radius($input-border-radius-lg);
}

.form-input-group-sm > .form-control,
.form-input-group-sm > .form-select,
.form-input-group-sm > .input-group__prefix,
.form-input-group-sm > .input-group__suffix,
.form-input-group-sm > .btn {
    padding: $input-padding-y-sm $input-padding-x-sm;
    @include font-size($input-font-size-sm);
    @include border-radius($input-border-radius-sm);
}

//
// Fake form-control
//

.fake-form-control {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $input-bg;
    border: $input-border-width solid $input-border-color;
    @include border-radius($input-border-radius, 0);
    @include box-shadow($input-box-shadow);
    @include transition($input-transition);
}

// Customize the `:focus` state to imitate native WebKit styles.

.form-control:focus ~ .fake-form-control {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    @if $enable-shadows {
        @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: $input-focus-box-shadow;
    }
}

// Customize the `:disabled` state

.form-control {
    &:disabled ~ .fake-form-control,
    &[readonly] ~ .fake-form-control {
        background-color: $input-disabled-bg;
        border-color: $input-disabled-bg;
    }
    &:disabled ~ .fake-form-control {
        cursor: not-allowed;
    }
}

.form-input-group.is-disabled {
    cursor: not-allowed;

    .input-group__prefix,
    .input-group__suffix {
        .btn {
            pointer-events: none;
            opacity: 0.45;
            cursor: not-allowed;
        }
    }
}

// Fake form-control - Form control Filled variant

.form-control--filled {
    ~ .fake-form-control {
        background-color: $input-filled-bg;
        border: $input-border-width solid $input-filled-border-color;
    }

    &:focus {
        ~ .fake-form-control {
            background-color: $input-filled-focus-bg;
            border-color: $input-filled-focus-border-color;
        }
    }
    &:disabled,
    &[readonly] {
        ~ .fake-form-control {
            background-color: $input-filled-disabled-bg;
            border-color: $input-filled-disabled-bg;
        }
    }
}
