@import "../../../assets/scss/component-base.scss";

//
// Circle loader
//

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

.circle-loader {
    position: relative;
    width: $circle-loader-width;
    height: $circle-loader-height;
}

.circle-loader-spinner {
    animation: rotate 1s linear infinite;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // margin: -50% 0 0 -50%;
    stroke: currentColor;
    stroke-linecap: round;
    width: $circle-loader-width;
    height: $circle-loader-height;
}

.circle-loader-spinner .circle-loader-spinner-path {
    // stroke: #c5c7d0;
    // stroke-linecap: round;
    animation: dash 1s ease-in-out infinite;
}

.circle-loader--lg {
    width: $circle-loader-width-lg;
    height: $circle-loader-height-lg;
}
.circle-loader--sm {
    width: $circle-loader-width-sm;
    height: $circle-loader-height-sm;
}
.circle-loader--xs,
.btn svg.circle-loader--xs {
    width: $circle-loader-width-xs;
    height: $circle-loader-height-xs;
}
