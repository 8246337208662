@import "../../../assets/scss/component-base.scss";

.card-lathe {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 0 16px;
    overflow: hidden;

    a {
        color: inherit;

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                .card-lathe__media img {
                    transform: scale(1.05);
                }
            }
        }
    }
}

.card-lathe__media {
    position: relative;
    overflow: hidden;
    background-color: $gray-50;
    padding-bottom: 75%; // 4:3

    img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        transform: scale(1);
        transition: transform 0.4s cubic-bezier(0, 0, 0.17, 1) 0s;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background: radial-gradient(ellipse at center center, rgba($gray-500, 0) 48%, rgba($gray-500, 0.2) 100%) repeat scroll 0 0;
    }
}

.card-lathe__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 8% 10%;
}

.card-lathe__title {
    // font-family: $font-family-lathes;
    // font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    @include font-size($font-size-base);
    font-weight: 700;
    text-transform: uppercase;

    // Truncate text at a specific number of lines
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@include media-breakpoint-up(sm) {
    .card-lathe__title {
        @include font-size($h5-font-size);
    }
}

@include media-breakpoint-up(xxl) {
    .card-lathe__title {
        @include font-size(1.375rem);
    }
}
