// Root
//

// #root {
//     display: flex;
//     flex-direction: column;
//     width: 100vw;
//     overflow-x: hidden;
//     overflow-y: scroll;
//     main {
//         flex-grow: 1;
//     }

//     @include media-breakpoint-up(xl) {
//         height: 100vh;
//     }
// }

html {
    //@include media-breakpoint-down(xl) {
        width: 100vw;
        overflow-x: hidden;
    //}
}
#root {
    width: 100%;
    @include media-breakpoint-up(xl) {
        display: flex;
        flex-direction: column;
        width: 100vw;
        min-height: 100vh;
        // height: 100vh;
        // overflow-x: hidden;
        // overflow-y: scroll;
        main {
            flex-grow: 1;
        }
    }
}
