@import "../../assets/scss/component-base.scss";

// Product Page layout

.pp-layout {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }
}

.pp-layout__left {
    width: 100%;

    @include media-breakpoint-up(lg) {
        width: 60%;
        padding-right: 2rem;
        flex-grow: 1;
    }
}

.product-information {
    width: 100%;
    padding: 2rem 0;

    @include media-breakpoint-up(lg) {
        width: 40%;
        max-width: 580px;
        padding-left: 2rem;
        padding-top: 2rem;
        padding-right: 0;
    }
    @include media-breakpoint-up(xl) {
        padding-left: 4rem;
        padding-top: 3rem;
    }
}

// Layout Left

.pp-layout__left {
    position: relative;
    background-color: $gray-100;

    &:before {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 100vw;
        background-color: $gray-100;

        @include media-breakpoint-down(lg) {
            right: -1.5rem;
        }
        @include media-breakpoint-down(md) {
            right: -1.25rem;
        }
        @include media-breakpoint-down(sm) {
            right: -1rem;
        }
    }
}

// Product Page Image Gallery

@include media-breakpoint-up(lg) {
    .product-page {
        .image-gallery {
            position: sticky;
            top: $header-main-height + 1rem;
        }
    }
}

.pp-image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(lg) {
        position: sticky;
        top: $header-main-height + 1rem;
    }
    @include media-breakpoint-up(xxl) {
        img {
            max-width: 800px;
        }
    }
}

// Layout Right

.product-information {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .product-heading {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-start;
    }

    h1 {
        @include font-size(1.875rem);
        font-weight: $font-weight-bold;
        margin-bottom: 0;
    }

    .product-description {
        color: $text-muted;
    }

    .product-features {
        display: flex;
        flex-direction: column;
        border: 1px solid $border-color;
    }
    .product-features__item {
        display: flex;
        flex-direction: column;
        padding: 0.5rem 0.75rem;
        border-bottom: 1px solid $border-color;

        &:last-child {
            border-bottom: 0;
        }

        .label {
            font-size: $font-size-sm;
            color: $text-muted;
        }
    }
}

.product-price {
    font-size: $font-size-lg;
    font-weight: $font-weight-semibold;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    del {
        font-weight: $font-weight-normal;
        font-size: $font-size-sm;
        margin-right: 0;
    }
}

.stock-info {
    font-size: $font-size-xs;
    margin-left: 0.5rem;
    color: $body-color;
    // letter-spacing: 0.025rem;

    &:before {
        content: "";
        position: relative;
        left: -0.5rem;
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: $gray-300;
    }

    &.in-stock {
        color: $success-700;

        &:before {
            background-color: $success;
        }
    }
    &.few-stock {
        color: $warning-800;

        &:before {
            background-color: $warning;
        }
    }
    &.out-of-stock {
        color: $danger;

        &:before {
            background-color: $danger;
        }
    }
}

.product-content-info {
    .accordion ul {
        list-style: none;
        padding-left: 1rem;
        li {
            position: relative;
            margin-bottom: 1rem;

            &:before {
                display: inline-block;
                position: absolute;
                top: 0.5rem;
                left: -1rem;
                width: 0.5rem;
                height: 0.5rem;
                // transform: rotate(calc(var(--rotation) * 36deg));
                background-image: url("/assets/icons/list-bullet.svg");
                line-height: 0;
                text-align: center;
                content: "";
            }

            &:nth-child(4n + 1) {
                --rotation: 6;
            }
        }
    }
}

// Related Products

.section-related-products {
    .section-title {
        font-weight: $font-weight-bold;
        @include font-size($h3-font-size);
        margin-bottom: 2rem;
    }
}

// Alvaro
// @include media-breakpoint-down(lg) {
//     .pp-layout__left {
//         &:before {
//             background-color: #fff;
//         }
//     }
// }
