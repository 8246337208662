@import "../../assets/scss/component-base.scss";

.checkout-page {
    overflow-x: hidden;
}

.page-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;

    .main {
        flex-grow: 1;
    }
}

// Section checkout

.section-checkout {
    margin-bottom: map-get($spacers, 7);
    border-bottom: 1px dashed $border-color;
    padding-bottom: map-get($spacers, 7);

    &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
    }

    .section-title {
        @include font-size($h5-font-size);
        font-weight: 700;
    }
}

.card-check {
    height: 100%;
}

.card-check--active {
    background-color: $primary-50;
    border: 1px solid $primary-300;
}

// Form mega options

.mega-option {
    display: flex;
    gap: map-get($spacers, 2);
    padding: 1rem;
    @include media-breakpoint-up(md) {
        padding: 1.5rem;
    }
    border: 1px solid $border-color;

    &.is-selected {
        background-color: $primary-50;
        border: 1px solid $primary-200;
    }
}

.mega-option__control {
}

.mega-option__label {
    flex-grow: 1;
}

.mega-option__label__title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    // margin-bottom: 1rem;
    font-weight: $font-weight-semibold;
}

.mega-option__description {
    font-size: $font-size-sm;
    margin-top: 1rem;
    color: $gray-600;

    p:last-child {
        margin-bottom: 0;
    }
}

// Order Summary

.section-order-summary {
    margin-bottom: map-get($spacers, 4);
    border-bottom: 1px dashed $border-color;
    padding-bottom: map-get($spacers, 4);

    .title {
        @include font-size($h5-font-size);
        font-weight: 700;
        letter-spacing: 0.04em;
        // font-family: $font-family-base;
        // letter-spacing: normal;
    }

    // Temporal
    .cart-line__img {
        background-color: $gray-200;
    }
}

.section-cart-totals {
    .title {
        @include font-size($h5-font-size);
        font-weight: 700;
        letter-spacing: 0.04em;
        // font-family: $font-family-base;
        // letter-spacing: normal;
    }
}
