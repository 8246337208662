@import "../../assets/scss/component-base.scss";

.header {
    position: sticky;
    z-index: $zindex-sticky;
    left: 0;
    right: 0;
    top: 0;
    transition: all 0.3s ease-in-out;
}

.header-topbar {
    display: none;
    position: relative;
    background-color: $gray-200;
    height: $header-topbar-height;
}

.header-main {
    background: rgba($primary-800, 0.96);
    @include media-breakpoint-up(lg) {
        background: $gradient-primary-90-deg;
    }
    backdrop-filter: blur(2px);
    color: $white;
    height: $header-main-mobile-height;

    > .container {
        display: flex;
        align-items: center;
        height: 100%;
    }
}

.header-main__nav {
    display: flex;
    align-items: center;

    > * {
        flex-shrink: 0;
    }
}

.navbar {
    display: none;
    height: $navbar-height;
    background: $gradient-primary-90-deg;
    backdrop-filter: blur(2px);
}

//
// Responsive
//

@include media-breakpoint-up(lg) {
    .header {
        top: -$header-topbar-height;
    }
    .header-topbar {
        display: block;
    }
    .header-main {
        // height: $header-main-height;
        height: 5rem;
        border-top: 2px solid #e5e7eb; /** Alvaro: Ñapa para que se vea el loader en posición fixed **/
    }
    .navbar {
        display: block;
    }
    .btn-menu-toggle {
        display: none;
    }
    .btn-search-toggle {
        display: none;
    }
}

@include media-breakpoint-down(lg) {
    .header-main {
        > .container {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
    .header-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .header-logo-img {
        width: 100px;
    }

    .header-main__nav {
        .btn-search {
            display: none;
        }
    }

    .btn-label {
        display: none;
    }
}

//
// OffcanvasMenu Toogle Button
//

.btn-menu-toggle {
    .icon-wrapper {
        position: relative;
        width: 1.25rem;
        height: 0.875rem;
    }

    span.line {
        display: block;
        position: absolute;
        height: 0.125rem;
        width: 100%;
        background: $white;
        border-radius: $border-radius-pill;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
            top: 0;
            transform-origin: left center;
        }
        &:nth-child(2) {
            top: 0.375rem;
            transform-origin: left center;
        }
        &:nth-child(3) {
            top: 0.75rem;
            transform-origin: left center;
        }
    }
    &.is-open span.line {
        &:nth-child(1) {
            transform: rotate(45deg);
            top: -0.0625rem;
            left: 0.1875rem;
        }
        &:nth-child(2) {
            width: 0%;
            opacity: 0;
        }
        &:nth-child(3) {
            transform: rotate(-45deg);
            top: 0.8125rem;
            left: 0.1875rem;
        }
    }
}

//
// Shopping cart button
//

.btn-shopping-cart {
    .cart-icon-wrapper {
        position: relative;
    }
    .counter {
        position: absolute;
        right: -0.5rem;
        top: -0.25rem;
        width: 1rem;
        height: 1rem;
        line-height: 1rem;
        text-align: center;
        font-size: 0.5625rem;
        font-weight: 600;
        border-radius: 50%;
    }
}

//
// Compact Navbar
//

.navbar {
    position: static;
    height: 100%;
    background: none;
    backdrop-filter: none;
    margin-left: 2rem;

    @include media-breakpoint-up(xxl) {
        margin-left: 2.5rem;
    }
}

.btn-user-account,
.btn-shopping-cart {
    padding: 0.5rem 0.75rem;
}

@include media-breakpoint-down(xl) {
    .btn-label {
        display: none;
    }
}
