// Badge variants
//

@mixin badge-variant($background, $color: color-contrast($background)) {
    color: $color;
    @include gradient-bg($background);
}

@mixin badge-light-variant($color, $background: tint-color($color, 90%)) {
    color: $color;
    background-color: $background;
}
