// Text truncate
// Requires inline-block or block for proper styling

@mixin is-scrollable() {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    overflow-y: overlay;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
}

@mixin scrollable-x() {
    overflow-x: auto;
    overflow-x: overlay;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    // Webkit scrollbar

    &::-webkit-scrollbar {
        height: 0;
        transition: height 0.3s;
    }
    &:hover::-webkit-scrollbar {
        height: 4px;
    }
    &::-webkit-scrollbar-track {
        background: $scrollbar-bg-color;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb-color;
        border-radius: 4px;
        border: 4px solid $scrollbar-bg-color;
    }
}
