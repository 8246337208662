// SVG icons
//
//

.icon-svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: currentColor;
}

//
// Icon Sizes
//

.icon-svg--sm {
    width: 1rem;
    height: 1rem;
}

.icon-svg--lg {
    width: 2rem;
    height: 2rem;
}
