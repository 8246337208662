@import "../../assets/scss/component-base.scss";

.sidebar-nav {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    .nav-link {
        display: block;
        padding: $nav-link-padding-y 0;
        @include font-size($nav-link-font-size);
        font-weight: $nav-link-font-weight;
        color: $nav-link-color;
        text-decoration: if($link-decoration == none, null, none);
        @include transition($nav-link-transition);

        &:hover,
        &:focus {
            color: $nav-link-hover-color;
            text-decoration: if($link-hover-decoration == underline, none, null);
        }

        // Disabled state lightens text
        &.disabled {
            color: $nav-link-disabled-color;
            pointer-events: none;
            cursor: default;
        }

        &.active {
            font-weight: $nav-link-active-font-weight;
            color: $nav-link-active-color;
        }
    }

    ul {
        list-style: none;
        padding-left: 1.25rem;
    }
}
