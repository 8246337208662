@import "../../../../assets/scss/component-base.scss";
//@import "../../../../assets/scss/components/forms/_form-input-group.scss";

//
// Custom Select
//

.formik-select-wrapper {
    position: relative;
}

.formik-select .form-label {
    width: 100%;
}

.form-select {
    position: relative;
    display: flex;
    flex-wrap: wrap; // For form validation feedback
    align-items: stretch;
    width: 100%;
    min-height: $input-height;

    > .form-control,
    .form-control {
        position: relative; // For focus state's z-index
        // flex: 1 1 auto;
        // width: 1%;
        // min-width: 0; // https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size

        flex: 1 1 0%;
        background-color: transparent;
        border-color: transparent;
        z-index: 2;
        @include text-truncate();
    }

    // Bring the "active" form control to the top of surrounding elements
    > .form-control:focus,
    .form-control:focus {
        z-index: 3;
        border-color: transparent;
    }

    .form-select-indicators {
        position: relative;
        z-index: 2;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        align-self: stretch;
        height: $input-height;
    }

    &.form-select--sm {
        min-height: $input-height-sm;
        @include font-size($form-select-font-size-sm);
        @include border-radius($form-select-border-radius-sm);
        .form-select-indicators {
            height: $input-height-sm;
        }
    }

    &.form-select--lg {
        min-height: $input-height-lg;
        @include font-size($form-select-font-size-lg);
        @include border-radius($form-select-border-radius-lg);
        .form-select-indicators {
            height: $input-height-lg;
        }
    }
}

.form-select.is-disabled {
    opacity: 0.5;
    pointer-events: none;

    .fake-form-control {
        background-color: $input-disabled-bg;
        border-color: $input-disabled-bg;
    }
}

//
// Forms Select Indicators
//

.form-select-arrow-indicator {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $gray-500;
    @include transition(color 0.15s ease-in-out);
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: shade-color($body-color, 20%);
        }
    }
}

.form-select-loading-indicator {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.form-select-clear-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    color: $body-color;
    background-color: $gray-200;
    @include transition($btn-transition);

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: shade-color($body-color, 20%);
            background-color: shade-color($gray-200, 5%);
        }
    }
    svg {
        width: 1rem;
        height: 1rem;
    }
}

//
// Forms Select Value
//

.form-select-value-container {
    position: relative; // For focus state's z-index
    z-index: 2;
    flex: 1 1 0%;
    display: grid;
    flex-wrap: wrap;
    align-items: center;

    .form-control {
        flex: 1 1 0%;
        display: inline-grid;
        grid-area: 1 / 1 / 2 / 3;
        grid-template-columns: 0px min-content;

        &:focus ~ .selected-value {
            display: none;
        }
    }
}

.selected-value {
    padding: $input-padding-y $input-padding-x;
    grid-area: 1 / 1 / 2 / 3;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.form-select--sm {
    .selected-value {
        padding: $input-padding-y-sm $input-padding-x-sm;
    }
}

.form-select--lg {
    .selected-value {
        padding: $input-padding-y-lg $input-padding-x-lg;
    }
}

//
// Forms Select Multivalue
//

.form-select-multivalue-container {
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 0%;
    align-items: center;
    padding-left: 8px;
    padding: 0.25rem 0.5rem;

    .chip {
        margin: 2px;
    }

    .form-control {
        min-width: 130px;
        padding: 0 0.25rem;
    }

    // .chips-wrapper {
    //     display: flex;
    //     flex-wrap: wrap;
    //     align-items: center;
    //     padding: 4px 0 4px 8px;

    //     .chip {
    //         margin: 2px;
    //     }
    // }
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */

.dot-flashing {
    position: relative;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $gray-400;
    color: $gray-400;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    left: -8px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $gray-400;
    color: $gray-400;
    animation: dotFlashing 1s ease-in-out infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 8px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $gray-400;
    color: $gray-400;
    animation: dotFlashing 1s ease-in-out infinite alternate;
    animation-delay: 1s;
}

@keyframes dotFlashing {
    0% {
        background-color: $gray-400;
    }
    50%,
    100% {
        background-color: $gray-100;
    }
}

//
// Form Select Dropdown menu
// components/select-dropdown.scss
//

//
// Form Select Validation
//

.formik-select.is-invalid .fake-form-control {
    border-color: $form-feedback-invalid-color;
}
.formik-select.is-valid .fake-form-control {
    border-color: $form-feedback-valid-color;
}

// Select dropdown menu
.select-dropdown-menu {
    position: fixed;
    z-index: $zindex-dropdown;
    display: flex;
    flex-direction: column;
    min-width: $dropdown-min-width;
    max-height: 300px;
    padding: $dropdown-padding-y $dropdown-padding-x;
    overflow-y: auto;
    margin: 0; // Override default margin of ul
    @include font-size($dropdown-font-size);
    color: $dropdown-color;
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    list-style: none;
    background-color: $dropdown-bg;
    background-clip: padding-box;
    border: $dropdown-border-width solid $dropdown-border-color;
    @include border-radius($dropdown-border-radius);
    box-shadow: $dropdown-box-shadow;
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.select-dropdown-item {
    //display: block;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%; // For `<button>`s
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    clear: both;
    font-weight: $font-weight-normal;
    color: $dropdown-link-color;
    text-align: inherit; // For `<button>`s
    text-decoration: if($link-decoration == none, null, none);
    //white-space: nowrap;
    background-color: transparent; // For `<button>`s
    border: 0; // For `<button>`s

    // Prevent dropdown overflow if there's no padding
    // See https://github.com/twbs/bootstrap/pull/27703
    @if $dropdown-padding-y == 0 {
        &:first-child {
            @include border-top-radius($dropdown-inner-border-radius);
        }

        &:last-child {
            @include border-bottom-radius($dropdown-inner-border-radius);
        }
    }

    &:hover,
    &:focus {
        color: $dropdown-link-hover-color;
        text-decoration: if($link-hover-decoration == underline, none, null);
        @include gradient-bg($dropdown-link-hover-bg);
    }

    &.active,
    &:active {
        color: $dropdown-link-active-color;
        text-decoration: none;
        @include gradient-bg($dropdown-link-active-bg);
    }

    &.disabled,
    &:disabled {
        color: $dropdown-link-disabled-color;
        pointer-events: none;
        background-color: transparent;
    }
}

.select-dropdown-item__meta {
    margin-left: auto;
    flex-shrink: 0;
}

.select-dropdown-item__graphic {
    flex-shrink: 0;
    // display: flex;
    // align-items: center;
}

.select-dropdown-item-secondary__text {
    font-size: $font-size-sm;
    color: $text-muted;
}
