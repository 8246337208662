@import "../../assets/scss/component-base.scss";

.sidebar-layout {
    .sidebar.my-account-sidebar {
        margin-bottom: 2rem;
    }
    @include media-breakpoint-up(lg) {
        .sidebar.my-account-sidebar {
            width: 250px;
        }
    }
    @include media-breakpoint-up(xl) {
        .sidebar.my-account-sidebar {
            width: 300px;
        }
    }
}

.my-account-sidebar__title {
    @include font-size(1.25rem);
    font-weight: $font-weight-bold;
}

.my-account-page {
    .card-user {
        background-color: $gray-100;
        border-radius: $border-radius;
        padding: 0.75rem;
    }
    .card-user__info {
        min-width: 1%;
        font-size: $font-size-sm;
    }
    .user-email {
        @include text-truncate;
        display: block;
        width: 100%;
        font-size: $font-size-xs;
    }
}
