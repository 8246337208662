@import "../../../assets/scss/component-base.scss";

//
// Skeleton
//

.skeleton {
    display: block;
    background-color: rgba($gray-700, 0.1);
    height: 1.5rem;
    width: 100%;
    min-width: 60px;
    transform-origin: 0px 55%;
    transform: scale(1, 0.6);
    border-radius: $border-radius;

    &:before {
        content: "";
    }

    animation: 1.5s ease-in-out 0.5s infinite normal none running animationPulse;

    &.skeleton--white {
        background-color: rgba($white, 0.1);
    }
}
.skeleton--square,
.skeleton--circular,
.skeleton--rectangular {
    transform-origin: 0 0;
    transform: none;
}
.skeleton--circular {
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    min-width: auto;
}
.skeleton--square {
    width: 2.5rem;
    height: 2.5rem;
    min-width: auto;
}

// .skeleton--pulse {
//     animation: 1.5s ease-in-out 0.5s infinite normal none running animationPulse;
// }
.skeleton--wave {
    position: relative;
    overflow: hidden;
    animation: none;
    &:after {
        animation: 1.6s linear 0.5s infinite normal none running animationWave;
        background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
        content: "";
        position: absolute;
        transform: translateX(-100%);
        inset: 0px;
    }
}

@keyframes animationPulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}

@keyframes animationWave {
    0% {
        transform: translateX(-100%);
    }

    50% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(100%);
    }
}
