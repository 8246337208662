@import "../../assets/scss/component-base.scss";
@import "../../assets/scss/components/nav.scss";

//
// Advantages Banner
//

.advantages-banner {
    padding: 1.75rem 0;
}

.advantages-banner__item {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.advantages-banner__label {
    line-height: 1.2;
}

//
// Footer
//

.footer-main {
    background-color: $gray-900;
    color: $gray-400;

    a {
        color: $gray-300;

        &:hover,
        &:focus {
            color: $white;
        }
    }
}

// Footer Brand block

.footer-brand {
    position: relative;
    display: flex;
    flex-direction: column;

    .site-description {
        max-width: 380px;
        font-style: italic;
    }

    @include media-breakpoint-up(xl) {
        .site-description {
            max-width: 280px;
        }
    }

    .nav-social-networks {
        margin-left: -0.75rem;
        // @include media-breakpoint-up(xl) {
        //     margin-left: -0.75rem;
        // }

        .nav-link {
            padding-left: 0.75rem;
            padding-right: 0.75rem;
        }

        @include media-breakpoint-between(sm, xl) {
            position: absolute;
            right: 0;
        }
    }
}

// Nav

.footer-links-nav {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    .nav-link {
        display: block;
        padding: $nav-link-padding-y 0;
        @include font-size($nav-link-font-size);
        font-weight: $nav-link-font-weight;
        color: $gray-400;
        text-decoration: if($link-decoration == none, null, none);
        @include transition($nav-link-transition);

        &:hover,
        &:focus {
            color: $white;
            text-decoration: if($link-hover-decoration == underline, none, null);
        }

        // Disabled state lightens text
        &.disabled {
            color: $gray-700;
            pointer-events: none;
            cursor: default;
        }
    }
}

.footer-links-title {
    font-size: $h6-font-size;
}

// Footerbar

.footer-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    border-top: 1px solid $gray-700;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}

.footer-payments {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        gap: 1rem;
    }
}

.footer-copyright {
    @include media-breakpoint-up(md) {
        order: -1;
    }
}
