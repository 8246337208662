// scss-docs-start stacks

// Interactivity

.scroll-smooth {
    scroll-behavior: smooth;
}

.scroll-auto {
    scroll-behavior: auto;
}

// .scrollable {
//     width: 100%;
//     height: 100%;
//     overflow-y: hidden;
//     overflow-x: hidden;
//     max-height: 100%;
//     position: absolute;
//     top: 0px;
//     left: 0px;
//     bottom: 0px;
//     right: 0px;
//     -webkit-overflow-scrolling: touch;
// }

// .scrollable.scrollable--y {
//     overflow-y: auto;
//     overflow-y: overlay;
//     scrollbar-width: thin;
//     -ms-overflow-style: none;
//     transform: translateZ(0);
// }
// .scrollable.scrollable--x {
//     overflow-x: auto;
//     scrollbar-width: thin;
//     -ms-overflow-style: none;
// }

.is-scrollable {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    overflow-y: overlay;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    // Webkit scrollbar
    &::-webkit-scrollbar {
        width: 0;
        transition: width 0.3s;
    }
    &:hover::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: $scrollbar-bg-color;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb-color;
        border-radius: 6px;
        border: 10px solid $scrollbar-bg-color;
    }
}

//
// Scrollable X
//
.scrollable-x {
    overflow-x: auto;
    overflow-x: overlay;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    // Webkit scrollbar

    &::-webkit-scrollbar {
        height: 0;
        transition: height 0.3s;
    }
    &:hover::-webkit-scrollbar {
        height: 4px;
    }
    &::-webkit-scrollbar-track {
        background: $scrollbar-bg-color;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb-color;
        border-radius: 4px;
        border: 4px solid $scrollbar-bg-color;
    }
}

// scss-docs-end stacks
