@import "../../../assets/scss/component-base.scss";

$checkout-step-counter-width: 1.875rem;
$checkout-step-counter-height: $checkout-step-counter-width;
$checkout-portlet-body-padding-left: calc($checkout-step-counter-width + 0.75rem);

.checkout-portlets {
    max-width: 900px;
}

.checkout-portlet {
    border-bottom: 1px solid $border-color;

    &--current {
        border-bottom: 1px solid $border-color;
    }

    .checkout-portlets &:last-child {
        border-bottom: none;
    }
}

// Chekout Porlet Header

.checkout-portlet-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1.5rem 0;
}

.checkout-portlets .checkout-portlet:first-child .checkout-portlet-header {
    padding-top: 0;
}

.checkout-step-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $checkout-step-counter-width;
    height: $checkout-step-counter-height;
    @include font-size($font-size-sm);
    color: $white;
    background-color: $primary;
    border-radius: $border-radius-pill;
    transition: all 0.25s cubic-bezier(0.88, 0.01, 0.11, 1);

    svg {
        fill: $white;
        width: 1.5rem;
        height: 1.5rem;
    }
}

.checkout-step-title {
    color: $primary-800;
    @include font-size(1.75rem);
    margin-bottom: 0;
    transition: all 0.25s cubic-bezier(0.88, 0.01, 0.11, 1);
}

// Chekout Porlet Body

.checkout-portlet-body {
    padding: 0 0.5rem 1.5rem 0.5rem;

    @include media-breakpoint-up(md) {
        padding: 0 0 2rem $checkout-portlet-body-padding-left;
    }
}

.btn-checkout-next-step {
    width: 100%;
    max-width: 300px;
    height: 56px;
}

// Current

.checkout-portlet--current {
    .checkout-step-counter {
        font-weight: $font-weight-semibold;
    }
}

// Pending

.checkout-portlet--pending {
    .checkout-step-counter {
        color: $gray-500;
        background-color: $gray-200;
    }

    .checkout-step-title {
        //color: $primary-300;
        @include font-size(1.125rem);
        color: $text-muted;
    }
}

// Completed

.checkout-portlet--completed {
    .checkout-portlet-header {
        padding-bottom: 0.5rem;
    }

    .checkout-step-counter {
        color: $primary;
        border: 1px solid $primary;
        background-color: transparent;

        svg {
            fill: $primary;
        }
    }

    .checkout-step-title {
        opacity: 0.75;
        @include font-size(1.125rem);
        //color: $text-muted;
    }

    .checkout-portlet-body {
        color: $text-muted;
        font-size: $font-size-sm;
    }
}
