// Carousel
//
@import "../../../assets/scss/component-base.scss";

.carousel {
    position: relative;
}

.carousel-stage {
    position: relative;
    scroll-behavior: smooth;
    overflow-x: auto;

    @each $breakpoint, $container-padding-x in $container-paddings-x {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            padding-left: $container-padding-x;
            padding-right: $container-padding-x;
        }
    }
    @include media-breakpoint-up(xxxl) {
        padding-left: calc(50% - ($container-inner-max-width / 2));
        padding-right: calc(50% - ($container-inner-max-width / 2));
    }

    > .row {
        flex-wrap: nowrap;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.carousel.js-overflowing .carousel-stage {
    justify-content: initial;
}

//
// Left/right controls for nav
//

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
    box-shadow: 0 2px 20px rgba($gray-900, 0.2);
}
.carousel-control-prev {
    //left: 0;
    @each $breakpoint, $container-padding-x in $container-paddings-x {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            left: $container-padding-x;
        }
    }
}
.carousel-control-next {
    //right: 0;
    @each $breakpoint, $container-padding-x in $container-paddings-x {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            right: $container-padding-x;
        }
    }
}

.carousel-control-prev,
.carousel-control-next {
    opacity: 0;
    display: none;
}

.carousel.js-end-overflow .carousel-control-next,
.carousel.js-begin-overflow .carousel-control-prev {
    opacity: 1;
    display: flex;
}

@media (hover: none) {
    .carousel-control-prev,
    .carousel-control-next {
        display: none !important;
    }
}

// Dark mode carousel

.carousel-dark {
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        filter: $carousel-dark-control-icon-filter;
    }

    .carousel-indicators [data-bs-target] {
        background-color: $carousel-dark-indicator-active-bg;
    }

    .carousel-caption {
        color: $carousel-dark-caption-color;
    }
}
