@import "../../../../../assets/scss/component-base.scss";

.modal-address {
    .modal-header {
        border-bottom: 1px solid $modal-header-border-color;
    }

    .modal-body {
        background-color: $gray-100;
    }

    // Form Wrapper
    form {
        display: flex;
        flex-direction: column;
        max-height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    .modal-dialog--scrollable {
        form {
            max-height: 100%;
            overflow: hidden;
        }
    }
}
