@import "../../../assets/scss/component-base.scss";

.section-home-hero {
    position: relative;
    min-height: 35vh;

    @include media-breakpoint-up(md) {
        min-height: 65vh;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    //color: $white;

    h1,
    .caption {
        color: $white;
    }
}

.home-hero__content {
    z-index: 1;
    position: relative;
    padding: 2rem 0;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    hgroup {
        max-width: 800px;
    }
}

.home-hero__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: $gray-900;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        //background: $primary-900;
        background: linear-gradient(180deg, rgba($gray-900, 0.35) 0%, rgba($gray-900, 0.55) 100%);
        opacity: 1;
    }
}

// Button serch modal toggle

.btn-modal-search-toggle {
    justify-content: flex-start;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 1rem;
    height: 56px;
    color: $body-color;

    .icon-left {
        margin-right: 0.75rem;
    }
}
