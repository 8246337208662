@import "../../../assets/scss/component-base.scss";

// .cart-line {
//     position: relative;
//     display: flex;
//     gap: 0.75rem;
//     padding: 1rem 0;
//     border-bottom: 1px solid $border-color;
// }

// .cart-line__img {
//     background-color: $gray-100;
//     width: 90px;
//     height: 90px;
//     flex-shrink: 0;
// }

// .cart-line__info {
//     flex-grow: 1;
//     display: flex;
//     flex-direction: column;
//     gap: 0.25rem;
// }

// .cart-line-title {
//     color: inherit;
//     font-weight: $font-weight-medium;
//     line-height: 1.3;
//     display: -webkit-box;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     overflow: hidden;
// }

// .cart-line-sku {
//     font-size: $font-size-sm;
// }

// .cart-list {
//     .cart-line:last-child {
//         border-bottom: 0;
//     }
// }

.cart-line--lite {
    .cart-line__img {
        width: 72px;
        height: 72px;
    }
    .cart-line-title {
        font-size: $font-size-sm;
        padding-right: 0;
        -webkit-line-clamp: 1;
    }
    .cart-line-quantity {
        font-size: $font-size-sm;
    }
    .cart-line-price {
        font-size: $font-size-sm;
        font-weight: $font-weight-medium;
    }
}
