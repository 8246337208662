@import "../../assets/scss/component-base.scss";

// Offcanvas navbar nav
//
//

.offcanvas-main-nav {
    top: $header-main-mobile-height;
    width: 100%;

    @include media-breakpoint-up(sm) {
        width: 94%;
    }

    .offcanvas-body {
        display: flex;
        flex-direction: column;
    }
}

.offcanvas-search {
    background: $gradient-primary-90-deg;
}

.offcanvas-navbar-nav,
.offcanvas-navbar-submenu {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    .nav-link {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        height: $offcanvas-nav-link-height;
        width: 100%;
        padding: $offcanvas-nav-link-padding-y $offcanvas-nav-link-padding-x;
        @include font-size($offcanvas-nav-link-font-size);
        font-weight: $offcanvas-nav-link-font-weight;
        color: $offcanvas-nav-link-color;
        @include transition($offcanvas-nav-link-transition);
        border-top: 1px solid $border-color;

        // Disabled state lightens text
        &.disabled {
            color: $offcanvas-nav-link-disabled-color;
            pointer-events: none;
            cursor: default;
        }
    }

    li:first-child .nav-link {
        border-top: none;
    }
}

// Provisional

.offcanvas-navbar-subnav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    padding-bottom: 1rem;

    .nav-link {
        height: auto;
        //padding: 0.75rem $offcanvas-nav-link-padding-x 0.75rem 4.25rem;
        padding: 0.75rem $offcanvas-nav-link-padding-x 0.75rem 2rem;
        border: 0;
    }
}

// End Provisional

.offcanvas-navbar-nav--dark {
    .nav-link {
        color: $navbar-dark-color;
        border-color: rgba($white, 0.1);

        &:hover,
        &:focus {
            color: $navbar-dark-hover-color;
        }

        // Disabled state lightens text
        &.disabled {
            color: $navbar-dark-disabled-color;
            pointer-events: none;
            cursor: default;
        }
    }
}

// Card Lathes
.offcanvas-lathes-list {
    .card-lathe__media {
        mix-blend-mode: multiply;
    }
    .card-lathe__title {
        font-size: 1rem;
    }
}

.offcanvas-navbar-submenu {
    .btn-back {
    }
}

// Sliding panels
//

.sliding-panels {
    position: relative;
    flex-grow: 1;
    //height: 100%;
}
.sliding-panel {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 0;
    transition: transform 0.4s ease;
    transform: translate3d(100%, 0, 0);
    display: flex;
    flex-direction: column;

    &.sliding-panel--hidden {
        display: none;
    }

    &.sliding-panel--visible {
        z-index: 1;
        transform: translate3d(0, 0, 0);
    }
    &.sliding-panel--parent-visible {
        transform: translate3d(-100%, 0, 0);
    }
}

// Backdrop

.offcanvas-main-nav ~ .offcanvas-backdrop {
    top: $header-main-mobile-height;
}
