@import "../../../assets/scss/component-base.scss";

// *** button
.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    //line-height: $btn-line-height;
    color: $body-color;
    text-align: center;
    text-decoration: if($link-decoration == none, null, none);
    white-space: $btn-white-space;
    vertical-align: middle;
    cursor: if($enable-button-pointers, pointer, null);
    // user-select: none;
    background-color: transparent;
    border: $btn-border-width solid transparent;
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
    @include transition($btn-transition);

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: $body-color;
            text-decoration: if($link-hover-decoration == underline, none, null);
        }
    }

    .btn-check:focus + &,
    &:focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
    }

    .btn-check:checked + &,
    .btn-check:active + &,
    &:active,
    &.active {
        @include box-shadow($btn-active-box-shadow);

        &:focus {
            @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        }
    }

    &:disabled,
    &.disabled,
    fieldset:disabled & {
        pointer-events: none;
        opacity: $btn-disabled-opacity;
        @include box-shadow(none);
    }
}

//
// Button Sizes
//

.btn--sm {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
    letter-spacing: 0.0125em;
}

.btn--lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

//
// Alternate buttons
//

// Solid (Default)
@each $color, $value in $button-colors {
    .btn--#{$color} {
        @include button-variant($value, $value);
    }
}

// Outline
@each $color, $value in $button-colors {
    .btn--outline-#{$color} {
        @include button-outline-variant($value);
    }
}

// Light
@each $color, $value in $button-colors {
    .btn--light-#{$color} {
        @include button-light-variant($value);
    }
}

// Ghost
@each $color, $value in $button-colors {
    .btn--ghost-#{$color} {
        @include button-ghost-variant($value);
    }
}

// Link
@each $color, $value in $button-colors {
    .btn--link-#{$color} {
        @include button-link-variant($value);
        padding: 0;
        text-align: left;
        font-weight: $font-weight-medium;
    }
}

// scss-docs-end btn-variant-loops

//
// Correct Secondary default colors
//

.btn--outline-secondary {
    color: $body-color;
    border-color: $input-border-color;

    &:hover,
    &:focus {
        color: shade-color($body-color, 20%);
    }
}

.btn--outline-white {
    border-color: rgba($white, 40%);

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: rgba($white, 10%);
            color: $white;
        }
    }
    &:focus {
        background-color: rgba($white, 20%);
        color: $white;
    }
}

.btn--light-secondary {
    color: $body-color;
    background-color: $gray-200;
    border-color: $gray-200;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: shade-color($body-color, 20%);
            background-color: shade-color($gray-200, 5%);
            border-color: shade-color($gray-200, 5%);
        }
    }

    &:focus {
        color: shade-color($body-color, 20%);
        background-color: shade-color($gray-200, 5%);
        border-color: shade-color($gray-200, 5%);
    }

    &:active {
        color: shade-color($body-color, 20%);
        background-color: shade-color($gray-200, 5%);
        border-color: shade-color($gray-200, 5%);
    }
}

.btn--ghost-secondary {
    color: $body-color;
    &:hover,
    &:focus {
        color: shade-color($body-color, 50%);
    }
}

.btn--link-secondary {
    color: $body-color;
    &:hover,
    &:focus {
        color: shade-color($body-color, 50%);
    }
}

//
// Correct Success default colors
//

// .btn--success {
//     color: $white;

//     &:hover,
//     &:focus {
//         color: $white;
//     }

//     &:disabled,
//     &.disabled {
//         color: $white;
//     }
// }

// .btn--outline-success {
//     color: shade-color($success-600, 5%);

//     &:hover,
//     &:focus {
//         color: shade-color($success-700, 10%);
//     }
// }

// .btn--light-success {
//     color: shade-color($success-600, 10%);

//     &:hover,
//     &:focus {
//         color: shade-color($success-700, 10%);
//     }
// }

// .btn--ghost-success {
//     color: shade-color($success-600, 10%);
//     &:hover,
//     &:focus {
//         color: shade-color($success-700, 10%);
//     }
// }

// .btn--link-success {
//     color: shade-color($success-600, 10%);
//     &:hover,
//     &:focus {
//         color: shade-color($success-700, 10%);
//     }
// }

//
// Link buttons
//

// Make a button look and behave like a link

// .btn--link {
//     font-weight: $font-weight-medium;
//     color: $btn-link-color;
//     text-decoration: $link-decoration;

//     &:hover {
//         color: $btn-link-hover-color;
//         text-decoration: $link-hover-decoration;
//     }

//     &:focus {
//         text-decoration: $link-hover-decoration;
//     }

//     &:disabled,
//     &.disabled {
//         color: $btn-link-disabled-color;
//     }

//     // No need for an active state here
// }

//
// Button with icons
//

.btn svg {
    fill: currentColor;
    display: block;
}

.btn {
    svg {
        width: 1.5rem;
        height: 1.5rem;
    }

    &.btn--sm {
        svg {
            width: 1.25rem;
            height: 1.25rem;
        }
        svg.icon-svg--sm {
            width: 1rem;
            height: 1rem;
        }
    }
    // .btn--lg {
    //     svg{
    //         width: 2rem;
    //         height: 2rem;
    //     }
    // }
}

.btn .icon-left {
    margin-right: 0.25rem;
}

.btn .icon-right {
    margin-left: 0.25rem;
}

.btn--icon-before {
    padding-left: $btn-padding-x - 0.5rem;

    &.btn--sm {
        padding-left: $btn-padding-x-sm - 0.25rem;
    }

    &.btn--lg {
        padding-left: $btn-padding-x-lg - 0.5rem;
    }
}

.btn--icon-after {
    padding-right: $btn-padding-x - 0.5rem;

    &.btn--sm {
        padding-right: $btn-padding-x-sm - 0.25rem;
    }

    &.btn--lg {
        padding-right: $btn-padding-x-lg - 0.5rem;
    }
}

// Button Icon Only

.btn--icon-only {
    padding: 0;
    height: 2.625rem;
    width: 2.625rem;

    &.btn--sm {
        padding: 0;
        height: 2rem;
        width: 2rem;
    }

    &.btn--lg {
        padding: 0;
        height: 3.125rem;
        width: 3.125rem;

        svg:not(.circle-loader-spinner) {
            width: 1.75rem;
            height: 1.75rem;
        }
    }
}

.btn--icon-only {
    &.is-loading {
        .icon-svg {
            display: none;
        }
        .circle-loader-spinner {
            margin: 0 !important;
        }
    }
}

// Ripple Effect

.btn {
    position: relative;
    overflow: hidden;
}

span.button-ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 300ms linear;
    background-color: rgba(255, 255, 255, 0.5);
}

@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}
