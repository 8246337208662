@import "../../../assets/scss/component-base.scss";

// Search Form

.search-form {
    width: 100%;

    .search-input-group {
        display: flex;
        width: 100%;
    }

    .form-floating {
        flex-grow: 1;
        background-color: #fff;

        &.placeholder-shown {
            label {
                opacity: 1;
            }
        }

        &.placeholder-shown > .form-control:not(:placeholder-shown) ~ label {
            opacity: 1;
        }

        > .form-control:focus {
            ~ label {
                opacity: 1;
            }
        }

        // + & {
        //     border-left: 1px solid $border-color;
        // }
    }

    .form-control {
        border-color: transparent;
        border: 0;
        font-size: $font-size-sm;

        &:focus {
            // Placeholder
            &::placeholder {
                color: transparent;
                opacity: 0;
            }
        }
    }

    .mobile-search-input-group {
        .form-control {
            min-height: 56px;
        }
    }
}

.search-form {
    .vr {
        background-color: $gray-200;
        opacity: 1;
    }
}

// .desktop-search-wrapper .search-form-wrapper,
// .desktop-search-wrapper .search-form {
//     max-width: 100% !important;
//     margin: 0 !important;
// }

// MODAl / OVERLAY

// .search-overlay {
//     background-color: $white;
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: $zindex-fixed;
//     width: 100%;
//     height: 100%;
// }
// .search-overlay-inner {
//     display: flex;
//     flex-direction: column;
//     padding-top: 7rem;
// }

.search-overlay {
    // .modal-body {
    //     padding: 0;
    // }
    .search-input-group {
        max-width: 800px;
        margin: 0 auto;
    }
    .search-form-results {
        position: relative;
        // Enable `flex-grow: 1` so that the body take up as much space as possible
        // when there should be a fixed height on `.modal-dialog`.
        flex: 1 1 auto;
        overflow-y: auto;
    }
}

// Overlay Btn Close
.search-overlay {
    .btn-close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        z-index: $zindex-fixed;
    }
}

// Overlay search form
.search-overlay {
    .search-input-group {
        border: 1px solid $border-color;
        margin: 3rem auto;
        box-shadow: 0 0.5rem 1rem rgba($gray-900, 5%);
        z-index: 1;
    }

    .mobile-search-input-group {
        .form-input-group svg.icon-svg {
            opacity: 1;
        }
        .form-control {
            padding-right: 3rem;
            padding-left: 0.75rem;
        }
        .fake-form-control {
            border: 0;
            border-bottom: 1px solid $input-border-color;
        }
        .form-control:focus ~ .fake-form-control {
            //background-color: $gray-100;
            border-bottom: 1px solid $gray-200;
            box-shadow: 0 0 15px rgba($gray-900, 0.15);
        }
    }
}

// Overlay search results
.search-overlay {
    .search-form-results {
        padding-top: 2rem;
        width: 100%;
        max-width: $container-max-width;
        margin-right: auto;
        margin-left: auto;

        @each $breakpoint, $container-padding-x in $container-paddings-x {
            @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                padding-right: $container-padding-x;
                padding-left: $container-padding-x;
            }
        }

        .card-product {
            .card-product__actions {
                display: none;
            }
        }
    }
}

// HOME HERO

.home-hero__advanced-search {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
}

// Search form

.home-hero__advanced-search {
    .search-form {
        max-width: 800px;
        margin: 0 auto;
        box-shadow: $dropdown-box-shadow;
        z-index: 1;
    }
}

// Search results

.home-hero__advanced-search {
    .search-form-results {
        position: absolute;
        top: 90px;
        left: 0;
        background-color: $dropdown-bg;
        width: 100%;
        overflow-y: auto;
        max-height: 35vh;
        height: auto;
        box-shadow: $dropdown-box-shadow;
        padding: 1.5rem;

        .card-product {
            .card-product__body {
                padding: 0.75rem;
            }
            .card-product__title {
                font-size: 0.9375rem;

                // Line clamp
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
            .card-product__actions {
                display: none;
            }
        }
    }
}
